<template>
  <dl class="row">
    <dt class="col-sm-3">{{ $t('cert.Subject') }}</dt>
    <dd class="col-sm-9">{{ cert.name }}</dd>

    <dt class="col-sm-3">{{ $t('cert.Sans') }}</dt>
    <dd class="col-sm-9">
      <CBadge color="light" shape="pill" v-for="san in cert.sans" v-bind:key="san">{{ san }}</CBadge>
    </dd>

    <dt class="col-sm-3">{{ $t('cert.IssueState') }}</dt>
    <dd class="col-sm-9">{{ cert.issue_state }}</dd>

    <dt class="col-sm-3">{{ $t('cert.IssueAt') }}</dt>
    <dd class="col-sm-9">{{ cert.issue_at }}</dd>

    <dt class="col-sm-3">{{ $t('cert.ExpiredAt') }}</dt>
    <dd class="col-sm-9">{{ cert.expired_at }}</dd>
    
    <template v-if="cert.issuer">
      <dt class="col-sm-3">{{ $t('cert.SSLIssuer') }}</dt>
      <dd class="col-sm-9">{{ cert.issuer.organization }}</dd>
    </template>
  </dl>
</template>

<style scoped>
dl {
  margin-bottom: 0;
}
</style>

<script>
export default {
  props: ['cert']
}
</script>
