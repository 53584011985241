<template>
  <div>
  <CModal color="info" size="lg" :closeOnBackdrop="false" :show.sync="mutatedShow" @hide="$emit('hide');" @save="$emit('save');" id="edit-modal" :addContentClasses="promoteShadowDomain === null ? '' : 'translucent-modal'">
    <CForm class="needs-validation" novalidate ref="domainForm">
      <CTabs :active-tab="activeTab" @update:activeTab="handleChangedTab">
        <CTab :title="$t('domain.BasicConfiguration')" active>
        <CCard>
          <CCardBody>
            <CRow form class="form-group" id="edit-input-domain">
              <CCol sm="3">{{ $t('domain.DomainName') }}</CCol>
              <CCol sm="9">
                <CInput
                  :disabled="domain && domain.pk"
                  :placeholder="$t('domain.PleaseEnterTheDomainName')"
                  v-model="domain.name"
                  id="domian_name"
                  :valid="domain_validator"
                  :is-valid="domain_validator"
                  :error="domainError"
                  @input="forceLowerCase($event)"
                  @blur="forceLowerCase($event, true)"
                  @keydown="catchInvalidChars"
                  style="margin-bottom: 6px"
                ></CInput>
                <div v-if="domainError" class="error-message">{{ domainError }}</div>
                <p class="domain-input-description" v-html="$t('message.DomainInputDescription')"/>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
          <CCard>
            <CCardBody>
              <DomainTrafficAction :domain="domain" />
            </CCardBody>
          </CCard>
          <CCard>
            <CCardBody>
              <CRow>
                <CCol sm="3">{{ $t('notes') }}</CCol>
                <CCol sm="9">
                  <CTextarea :description="$t('optional')" v-model="domain.notes"></CTextarea>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
          <CCard>
            <CCardBody>
              <CRow>
                <CCol sm="3">{{ $t('certificate_configuration') }}</CCol>
                <CCol sm="9">
                  <input
                          type="checkbox"
                          class="custom-checkbox mr-2"
                          :checked="domain.sslConfig.isAutoGenerateCertificate"
                          @click="autoGenerateCertificate()"
                        />
                  <label class="mb-0 custom-label">{{ $t('auto_generate_certificate') }}</label>
                </CCol>
              </CRow>  
            </CCardBody>
          </CCard>
      </CTab> 

      <CTab :title="$t('domain.ShadowDomain')">
        <CCard>
          <CCardBody>
            <CRow >
              <CCol sm="3">
                {{ $t('domain.ShadowDomain') }}：
                <CAlert color="info">{{ $t('domain.ShadowDomainHelp') }}</CAlert>
              </CCol>
              <CCol sm="9">
                <div v-if="domain.shadows.length > 0" 
                     style="display: flex; align-items: flex-end; gap: 12px">
                  <div style="flex: 1">
                    <CInput
                        type="text"
                        :label="$t('Search')"
                        :placeholder="$t('message.SearchShadowDomain')"
                        v-model="shadowSearch"
                        @keypress="handleShadowSearch"
                    >
                      <template #append>
                        <CButton type="submit" color="primary" @click="searchShadowDomains">{{
                            $t('Search')
                          }}
                        </CButton>
                      </template>
                    </CInput>
                  </div>
                  <div>
                    <CSelect
                        :label="$t('sort')"
                        @update:value="handleSortChange"
                        :value.sync="shadowDomainSortBy"
                        :options="sortOptions"
                    />
                  </div>
                </div>
                <CListGroup :key="shadowsRefKey" >
                  <CListGroupItem v-for="(shadow, index) in shadowDomains" v-bind:key="shadow.pk" :class="{ 'subdomain-shadow-domain': shadow.__subdomain }">
                    <div style="display: flex; align-items: center;">
                      <div style="flex: 1">
                        <span v-if="shadow.__subdomain" style="padding-left: 20px"></span>
                        {{ shadow.name }}
                      </div>
                      <div class="shadow-list-action-btns">
                        <CButton v-if="shadow.pk"
                                 @click="promoteShadowDomain = shadow"
                                 class="promote-btn"
                                 v-c-tooltip="{content: $t('message.promote_shadow'), delay: 500, appendToBody: true}">
                          <CIcon name="cil-chevron-double-up"/>
                        </CButton>
                        <CButtonClose v-on:click="removeShadow(shadow)"/>
                      </div>
                    </div>
                  </CListGroupItem>
                  <CListGroupItem class="d-flex justify-content-between align-items-center">
                      <CTextarea
                        name="shadow_domain"
                        v-model="newShadowName"
                        style="width: 95%"
                        :is-valid="formValidStates.shadow_domain"
                        @keyup="handleShadowDomainInputKeyup"
                        :description="$t('InputFormat') + ': www.domain.com'"
                        :placeholder="$t('domain.PleaseEnterTheDomainName')"
                      >
                      </CTextarea>
                      <CButton class="close" size="sm" v-on:click="addShadow" color="black">
                          +
                      </CButton>
                  </CListGroupItem>
                </CListGroup>
              </CCol>
            </CRow>

            <!-- <CRow>
              <CCol sm="3">
                {{ $t('domain.AliasJump') }}
                <CBadge size="sm" color="info"
                  v-c-tooltip="{content: $t('message.alias_redirect')}">i</CBadge>
              </CCol>
              <CCol sm="9">
                <CSwitch class="mx-1" :checked.sync="domain.alias_redirect" color="success" />
              </CCol>
            </CRow> -->
            </CCardBody>
        </CCard>
      </CTab>


      <CTab :title="$t('domain.AdvancedConfiguration')">
        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="3">
                {{ $t('domain.CacheStrategy') }}
                <CBadge size="sm" color="info"
                  v-c-tooltip="{content: $t('message.cdn_intelligent_caching')}">i</CBadge>
              </CCol>
              <CCol sm="9">
                <CacheStrategyControl :domain="domain" ref="cacheStrategyControl"></CacheStrategyControl>
              </CCol>
            </CRow>
            </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>

        <CRow form class="form-group" id="edit-input-source">
          <CCol sm="3">
            {{ $t('domain.AdvancedRules') }}：
            <CBadge size="sm" color="info"
                  v-c-tooltip="{content: $t('message.intelligent_acceleration_defense')}">i</CBadge>
          </CCol>
          <CCol sm="9">
            <CListGroup>
              <CListGroupItem class="d-flex justify-content-between align-items-center">
                <div>
                  {{ $t('domain.HSTS') }}
                  <CBadge size="sm" color="info"
                    v-c-tooltip="{content: $t('helps.hsts_use')}">
                  ?
                  </CBadge>
                </div>
                <input type="checkbox" v-model="domain.sslConfig.is_hsts" />
              </CListGroupItem>

              <CListGroupItem class="d-flex justify-content-between align-items-center">
                <div>
                  {{ $t('domain.WildcardHSTS') }}
                  <CBadge size="sm" color="info"
                    v-c-tooltip="{content: $t('helps.wildcard_hsts_use')}">
                  ?
                  </CBadge>
                </div>
                <input type="checkbox" v-model="domain.sslConfig.is_wildcard_hsts" />
              </CListGroupItem>

              <CListGroupItem class="d-flex justify-content-between align-items-center">
                <div>
                  {{ $t('domain.SocketSupport') }}
                  <CBadge size="sm" color="info"
                    v-c-tooltip="{content: $t('helps.websockets_use')}">
                  ?
                  </CBadge>
                </div>
                <input type="checkbox" value="websocket" v-model="domain.strategies" />

                <!-- <CSwitch type="checkbox" class="mx-1" v-model="domain.strategies.socket" color="primary" /> -->
              </CListGroupItem>


              <CListGroupItem class="d-flex justify-content-between align-items-center">
                <div>
                  {{ $t('domain.DdosHighDefense') }}
                  <CBadge size="sm" color="info"
                    v-c-tooltip="{content: $t('helps.protection_reflection_attacks_etc')}">
                  ?
                  </CBadge>
                </div>
                <CBadge color="success" @click="showContact()">✔</CBadge>
              </CListGroupItem>
            </CListGroup>
          </CCol>
        </CRow>
        </CCardBody>
        </CCard>

        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="3">
                {{ $t('domain.ACMEConfigure') }}
                <CBadge size="sm" color="info" 
                        v-c-tooltip="{content: $t('domain.ACMEConfigureDescription')}">i</CBadge>
              </CCol>
              <CCol sm="9">
                <div>
                  <CInputCheckbox :checked.sync="is_bypass_acme"
                                  :label="$t('domain.IsBypassACMEDescription')">

                  </CinputCheckbox>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="3">
                {{ $t('domain.ProxyHeaderConfiguration') }}
                <CBadge size="sm" color="info" 
                        v-c-tooltip="{content: $t('message.ProxyHeaderTooltip')}">i</CBadge>
              </CCol>
              <CCol sm="9">
                <ProxyConfiguration v-if="mutatedShow" 
                                    :domain="domain" 
                                    @update="handleProxyConfigurationUpdate"/>                
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CTab>
      <CTab :title="$t('domain.GuardConfiguration')">
        <CCard>
          <CCardBody>
            <GuardConfiguration :guard-config="domain.guardConfig" ref="guardConfiguration"></GuardConfiguration>
          </CCardBody>
        </CCard>
      </CTab>
      </CTabs>
    </CForm>

    <template slot="header" v-if="sourceDomain">
      <h5 class="modal-title">{{ $t('domain.DomainNameConfiguration') }}: {{ sourceDomain.name }}</h5>
    </template>

    <template slot="footer-wrapper">
      <footer class="modal-footer" style="justify-content: space-between;">
        <CButtonGroup>
          <CButton
            color="danger"
            type="submit"
            size="sm"
            @click="confirmStop"
            v-if="domain.pk && domain.status!='disabled'"
          >
            <CIcon name="cil-media-pause" />&nbsp;
            <span>{{ $t('Deactivate') }}</span>
          </CButton>

          <CButton
            color="danger"
            type="submit"
            size="sm"
            @click="resume"
            v-if="domain.pk && domain.status=='disabled'"
          >
            <!--<CSpinner color="white" size="sm" v-if="onDeploy" />-->
            <CIcon name="cil-media-play" />&nbsp;
            <span>{{ $t('Restore') }}</span>
          </CButton>
          <CButton
            color="danger"
            type="submit"
            size="sm"
            @click="remove"
            v-if="domain.pk"
          >
            <!--<CSpinner color="white" size="sm" v-if="loadingRemove" />-->
            <CIcon name="cil-x-circle" />&nbsp;
            <span>{{ $t('Delete') }}</span>
          </CButton>
          </CButtonGroup>
        <CButtonGroup>
          <CButton @click="handleCancelModal" color="danger">{{ $t('Cancel') }}</CButton>
          <CButton color="primary" type="submit" @click="save" id="edit-submit">
            <CIcon name="cil-check" />&nbsp;
            <span v-if="domain.pk">{{ $t('Save') }}</span>
            <span v-else>{{ $t('NextStep') }}</span>
          </CButton>
        </CButtonGroup>
      </footer>
    </template>
  </CModal>

  <CModal :title="$t('message.DomainDeactivationDangerConfirmation')" color="warning" :show.sync="stopConfirmModal" id="stopConfirmModal">
      <div>
        <CAlert color="danger">
          <p><strong>{{ $t("Domain") }}： {{ domain.name }}</strong></p>
          <p>{{ $t('message.AfterDomainDeactivation') }}</p>
        </CAlert>
        <CAlert color="info">

          <strong>{{ $t('contact_us.having_problems_message') }}</strong>
          <Contacts />
        </CAlert>
      </div>
      <template slot="footer-wrapper">
        <footer class="modal-footer" style="justify-content: space-between;">
          <CButtonGroup>
            <CButton @click="stopConfirmModal = false" color="success">{{ $t('contact_us.ContactCustomerServiceToHelpAccess') }}</CButton>
          </CButtonGroup>
          <CButtonGroup>
            <CButton
              type="submit"
              size="sm"
              @click="stop"
            >
              <!--<CSpinner color="gray" size="sm" v-if="onDeploy" />-->
              <CIcon name="cil-media-pause" />&nbsp;
              <span>{{ $t('message.InsistOnDeactivating') }}</span>
            </CButton>
          </CButtonGroup>

        </footer>
      </template>
    </CModal>

    <CModal :centered="true" :closeOnBackdrop="false" color="warning" :show="showConfirmPromoteShadow">
      <div style="display: flex">
        <div style="padding-top: 5px">
          <CIcon name="cil-swap-vertical" size="xl"></CIcon>
        </div>
        <div style="padding-left: 10px">
          <i18n path="message.promote_shadow_message" tag="span">
            <template v-slot:shadowDomain>
              <strong>{{ promoteShadowDomain ? promoteShadowDomain.name : '' }}</strong>
            </template>
            <template v-slot:domain>
              <strong>{{ domain.name }}</strong>
            </template>
          </i18n>
        </div>
      </div>
      <template slot="header">
        <h5 class="modal-title">{{ $t('message.promote_shadow') }}: {{ promoteShadowDomain ? promoteShadowDomain.name : '' }}</h5>
      </template>

      <template slot="footer-wrapper">
        <footer class="modal-footer">
            <CButton color="secondary" @click="promoteShadowDomain = null">{{ $t('Cancel') }}</CButton>
            <CButton color="warning" @click="promoteShadow(promoteShadowDomain)" :disabled="promotingShadowDomain">{{ $t('proceed') }}</CButton>
        </footer>
      </template>
    </CModal>
  </div>
</template>

<style>
  #edit-modal .modal-content {
    transition: opacity 0.2s ease-in-out;
  }

  .translucent-modal {
    opacity: 0;
  }
  
  .domain-input-description {
    font-size: 0.9em;
    color: #768192;
  }
  
  .domain-input-description span {
    text-decoration: underline;
  }
</style>

<style scoped>
  .shadow-list-action-btns {
    display: flex;
    align-items: center;
  }

  .promote-btn {
    margin-right: 10px;
    padding: 0;
  }
  
  .tab-pane > .card:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .error-message {
  color: red;
  }
  .subdomain-shadow-domain {
    background-color: #fbfbfb;
  }
</style>

<script>
import psl from "psl";
import validUrl from "valid-url";
import Contacts from '@/containers/Contacts'
import { EventBus } from '@/plugins/event-bus.js';
import DomainValidationMixin from '@/utilities/DomainValidationMixin';
import GuardConfiguration from '@/views/domain/GuardConfiguration';
import getDefaultDomainConfig from '@/utilities/getDefaultDomainConfig';
import {promoteShadowDomain} from '@/utilities/api';
import {DomainAction} from '@/utilities/websocket';
import CacheStrategyControl from "@/views/domain/CacheStrategyControl";
import DomainTrafficAction from "@/views/domain/DomainTrafficAction";

import {MULTIPLE_VALUES_SPLITTER} from '@/utilities/constants';

import Fuse from 'fuse.js';
import ProxyConfiguration from '@/views/domain/ProxyConfiguration';

const SORT_BY_OLDEST_TO_NEWEST = 'oldest_to_newest';
const SORT_BY_NEWEST_TO_OLDEST = 'newest_to_oldest';
const SORT_BY_NAME = 'name';


export default {
  name: "EditDomain",
  mixins: [DomainValidationMixin],
  components: {
    ProxyConfiguration,
    DomainTrafficAction,
    CacheStrategyControl,
    GuardConfiguration,
    Contacts,
  },
  computed: {
    domain_ws() {
      return this.$store.state.websocket.domain_ws;
    },
    showConfirmPromoteShadow: function() {
      return this.promoteShadowDomain !== null;
    },
    useSearchResults() {
      return Boolean(this.shadowSearchResults);
    },
    shadowDomains: function() {
      const shadows = this.useSearchResults ? this.shadowSearchResults : this.domain.shadows;
      
      if (this.shadowDomainSortBy === SORT_BY_NAME) {
        const shadowDomainsByDomain = this.groupShadowDomainsByDomain(shadows);
        const shadowDomains = [];
        const domainsSorted = Object.keys(shadowDomainsByDomain).sort();
        
        for (let d of domainsSorted) {
          shadowDomains.push(this.shadowDomainsMap[d]);
          
          for (let dd of shadowDomainsByDomain[d]) {
            shadowDomains.push({...this.shadowDomainsMap[dd], __subdomain: true});
          }
        }
        
        return shadowDomains;
      }

      return this.sortShadowDomainsByCreatedDate(shadows);
    },
    shadowDomainsMap: function() {
      const shadowDomainMap = {};
      for (let s of this.domain.shadows) {
        shadowDomainMap[s.name] = s;
      }
      
      return shadowDomainMap;
    },
    is_bypass_acme: {
      get() {
        return this.domain.sourceConfig.is_bypass_acme || false; 
      },
      set(value) {
        this.domain.sourceConfig.is_bypass_acme = value;
      },
    },
  },
  watch: {
    editShow() {
      this.mutatedShow = this.editShow
      if (this.editShow) {
        this.initWs();
      }
    },
    stopConfirmModal() {
      if (this.stopConfirmModal) {
        this.initWs();
      } else {
        this.$emit('onStopConfirmModal');
      }
    },
    mutatedShow() {
      this.$emit('update:editShow', this.mutatedShow)
    },
    sourceDomain(newVal) {
      // reset to first tab
      this.activeTab = 0;
      this.promoteShadowDomain = null;
      this.promotingShadowDomain = false;

      // reset guardConfigPendingValues
      this.guardConfigPendingValues = {};

      const defaultDomainConfig = getDefaultDomainConfig();

      const isAdd = !newVal || !newVal.pk;
      if (isAdd) {
        this.domain = defaultDomainConfig
      } else {
        this.domain = newVal;
        this.domain.guardConfig = {...defaultDomainConfig.guardConfig, ...newVal.guardConfig};
        this.domain.sourceConfig = {...defaultDomainConfig.sourceConfig, ...newVal.sourceConfig};
      }
    }
  },
  data() {
    return {
      errors: [],
      issueTask: null,
      stopConfirmModal: false,
      newShadowName: '',
      newRuleText: '',
      domain: getDefaultDomainConfig(),
      activeTab: 0,
      guardConfigPendingValues: {},
      Domain: "",
      shadowsRefKey: 0,
      loadingRemove: false,
      pendingValues: {},
      formValidStates: {},
      mutatedShow: false,
      onDeploy: false,
      isDeleteDomain: false,
      promoteShadowDomain: null,
      promotingShadowDomain: false,
      shadowSearch: "",
      shadowSearchResults: null,
      shadowDomainSortBy: SORT_BY_NAME,
      sortOptions: [
        { value: SORT_BY_NAME, label: this.$t('name') },
        { value: SORT_BY_NEWEST_TO_OLDEST, label: this.$t('newest_to_oldest') },
        { value: SORT_BY_OLDEST_TO_NEWEST, label: this.$t('oldest_to_newest') },
      ],
      domainError : ''
    };
  },
  props: {
    sourceDomain: Object,
    editShow: Boolean,
    is_root: Boolean,
  },
  mounted() {
    this.domain = this.sourceDomain;
    EventBus.$on('domainError', this.handleDomainError);
  },
  beforeDestroy() {
    EventBus.$off('domainError', this.handleDomainError);
  },
  methods: {
    handleDomainError(errorMessage) {
      const domainName = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
      this.domainError = domainName;
    },
    autoGenerateCertificate(){
      if(this.domain.sslConfig.isAutoGenerateCertificate){
        this.domain.sslConfig.isAutoGenerateCertificate = false;
      }else{
        this.domain.sslConfig.isAutoGenerateCertificate = true;
      }
    },
    initWs() {
      this.domain_ws.onMessage = function(event, result) {
        if (result.data.hasOwnProperty('error')) {
          this.flash(result.data.error, "error", {timeout: 3000})
          return
        }

        if (result.action === DomainAction.DOMAIN_DEACTIVATE) {
          if (result.data.dp.success) {
            if (result.data.dp.result.success) {
              this.flash(this.$t("action.success"), "success", { timeout: 3000 });
              this.onDeploy = false;
              this.stopConfirmModal = false;
              this.$emit('refresh');
            }
          }
        } else if (result.action === DomainAction.DOMAIN_DELETE) {
          if (result.data.dp.success) {
            if (result.data.dp.result.success) {
              this.flash(this.$t('message.SuccessfullyDeleted'), "success", { timeout: 5000 });
              this.onDeploy = false;
              this.stopConfirmModal = false;
              this.$emit('hide');
              this.$emit('refresh');
            }
          }
        } else if (result.action === DomainAction.DOMAIN_RESUME) {
          if (result.data.dp.success) {
            if (result.data.dp.result.success) {
              this.flash(this.$t("action.success"), "success", { timeout: 3000 });

              this.onDeploy = false;
              this.$emit('hide');
              this.$emit('refresh');
            }
          }
        }
      }.bind(this)
    },
    showContact() {
      EventBus.$emit('show-contact');
    },
    removeShadow(shadow) {
      this.domain.shadows = this.domain.shadows.filter((d) => d.name !== shadow.name);
      this.shadowSearchResults = this.shadowSearchResults.filter((d) => d.name !== shadow.name);
      this.shadowsRefKey++;
    },
    addShadow: function() {
      const {success, failed, errors} = this.processShadowDomainsInput(this.newShadowName);

      if (!success) {
        this.newShadowName = failed.join('\n');
        if (errors.length > 0) {
          errors.forEach((error) => {
            this.flash(error, 'error', { "timeout": 3000 });
          });
        }
        return;
      }

      this.newShadowName = '';
    },
    processShadowDomainsInput: function (shadowDomainsInput) {
      const domains = shadowDomainsInput.split(MULTIPLE_VALUES_SPLITTER);
      const existingShadowDomains = (this.domain.shadows ? this.domain.shadows : []).map((s) => s.name);

      const domainsWithErrors = [];
      const errorMessage = [];
      for (let d of domains) {
        d = d.toLowerCase().trim()
        if (!d.length)
          continue;

        const isSameAsMainDomain = d === this.domain.name;
        if (isSameAsMainDomain) {
          domainsWithErrors.push(d);
          errorMessage.push(this.$t('message.ShadowDomainSameAsMain', [d]));
          continue;
        } else if (existingShadowDomains.includes(d)) {
          domainsWithErrors.push(d);
          errorMessage.push(this.$t('message.ShadowDomainAlreadyExists', [d]));
          continue;
        } else if (!this.shadow_domain_validator(d)) {
          domainsWithErrors.push(d);
          errorMessage.push(this.$t('message.InvalidShadowDomains', [d]));
          continue;
        }

        const shadow = {
          "domain": this.domain.pk,
          "name": d
        };

        this.domain.shadows.push(shadow);
      }

      return { success: domainsWithErrors.length === 0, failed: domainsWithErrors, errors: errorMessage}
    },
    confirmStop(){
      this.stopConfirmModal = true;
      this.$emit('hide');
    },
    sendDeploy(action, domain) {
      this.domain_ws.sendMessage(action, {domains: [domain]})
    },
    stop() {
      this.onDeploy = true;
      this.domain.deploy_status = "running";
      this.sendDeploy(DomainAction.DOMAIN_DEACTIVATE, this.domain.name);

      this.stopConfirmModal = false;
    },
    remove() {
      this.loadingRemove = true;
      this.domain.deploy_status = "running";
      this.sendDeploy(DomainAction.DOMAIN_DELETE, this.domain.name);
      this.$emit('hide');
    },
    resume() {
      this.onDeploy = true;
      this.domain.deploy_status = "running";
      this.sendDeploy(DomainAction.DOMAIN_RESUME, this.domain.name);
      this.$emit('hide');
    },
    patchSave() {

    },
    save() {
      //assign(this.sourceDomain, this.domain);

      //let isNew = this.domain.pk == null;
      //let self = this;
      let form = this.$refs.domainForm

      if (!form.checkValidity()) {
        this.flash(this.$t('message.DataVerificationError'), "error", { timeout: 3000 });
        return;
      }
      form.classList.add('was-validated');

      this.domain.sourceConfig = { ...this.domain.sourceConfig, "full_url": this.domain.sourceConfig.full_url.split(',').map(url => url.trim()).join(',') };

      let isValidSource = true;
      if (this.domain.sourceConfig.redirect) {
        // validate as url
        isValidSource = Boolean(validUrl.isWebUri(this.domain.sourceConfig.full_url));
      } else {
        isValidSource = this.source_validator(this.domain.sourceConfig.full_url);
      }
      
      if (!(this.domain_validator(this.domain.name) && isValidSource)) {
        this.flash(this.$t('message.InputDataVerificationError'), "error", { timeout: 3000 });
        return;
      }

      if (this.newShadowName !== "") {
        const {success, failed, errors} = this.processShadowDomainsInput(this.newShadowName);
        if (!success) {
          if (errors.length > 0) {
            errors.forEach((error) => {
              this.flash(error, 'error', { "timeout": 3000 });
            });
          }

          this.newShadowName = failed.join('\n');
          this.formValidStates.shadow_domain = false;
          return;
        }

        this.newShadowName = '';
        this.formValidStates.shadow_domain = null;
      }
      
      this.$refs.guardConfiguration.processPendingValues();
      if (this.domain.guardConfig.error_code) {
        const statusCodes = [401, 425, 502];
        for (let sc of statusCodes) {
          // we allow null or empty values so skip input validation
          if (!this.domain.guardConfig.error_code[sc]) {
            continue;
          }
          
          if (!this.source_validator(this.domain.guardConfig.error_code[sc])) {
            this.flash(this.$i18n.t('message.StatusCodeUrlInvalid', { status_code: sc }), "error", { timeout: 5000 });
            return;
          }
        }
      }

      this.$refs.cacheStrategyControl.addNewRule();
      this.$emit('save', this.domain);

      // axios({
      //   method: isNew ? "POST" : "PATCH",
      //   url: isNew
      //     ? `domain/`
      //     : `domain/{domain}/`,
      //   data: self.domain,
      //   urlParams: {
      //     group: this.$route.params.pk,
      //     domain: this.domain.name
      //   }
      // })
      //   .then(response => {
      //     self.domain = response.data;
      //     if (response.status == "201") {

      //       this.flash(this.$t("domain.SaveSuccess"), "success", {
      //         timeout: 5000
      //       });
      //       // self.$emit('hide');
      //       //self.showSslConfig(self.domain);

      //       // this.$root.$emit("bv::hide::modal", 'editModal');
      //       //this.fetchData();

      //       //this.diagnose(self.domain);

      //       this.$emit('refresh');
      //     }
      //     this.$emit('hide');
      //   })
      //   .catch(error => {
      //     // forgot to include "catch"
      //     console.log(error);
      //   });
    },
    handleChangedTab(activeTab) {
      this.activeTab = activeTab;
    },
    handleGuardConfigPendingValues(pendingValues) {
      Object.assign(this.guardConfigPendingValues,  pendingValues);
    },
    forceLowerCase(event, blur=false) {
      if (blur) {
        this.domain.name = event.target.value.toLowerCase()
        this.domain.name = this.domain.name.trim()

        event.target.value = this.domain.name
        event.target.dispatchEvent(new Event('input'));
      } else {
        this.domain.name = event.toLowerCase()
        this.domain.name = this.domain.name.trim()
      }
    },
    handleShadowDomainInputKeyup(e) {
      const field = e.target.name;
      let value = e.target.value;

      const shadowDomains = value.split(MULTIPLE_VALUES_SPLITTER);
      if (shadowDomains.length > 1) {
        this.formValidStates[field] = null;
      } else {
        value = value.toLowerCase().trim()
        this.formValidStates[field] = this.shadow_domain_validator(value);
      }
    },
    handleCancelModal() {
      this.$emit('hide');
      this.$emit('refresh');
      this.domainError="";
    },
    async promoteShadow(shadowDomain) {
      if (this.promoteShadowDomain !== null){
        if (this.promoteShadowDomain.name.startsWith("*.")) {
          this.promoteShadowDomain = null;
          this.promotingShadowDomain = false;
          this.flash(this.$t('domain.WildcardShadowDomain'), "error",{ timeout: 3000 });
          return;
        }
      }
      this.promotingShadowDomain = true;

      const response = await promoteShadowDomain(this.domain.name, shadowDomain);

      if (response.status >= 200) {
        this.flash(this.$t('action.success') + ': ' + this.$t('domain.WaitingForRedeployment'), "success", { timeout: 3000 });

        this.promoteShadowDomain = null;

        this.$emit('hide');
        this.$emit('refresh');
      } else {
        this.flash(this.$t("action.fail"), "error");
      }

      this.promotingShadowDomain = false;
    },
    searchShadowDomains(e) {
      // need to preventDefault so form does not submit
      e.preventDefault();
      
      if (!this.shadowSearch.length) {
        this.shadowSearchResults = null;
        return;
      }
      /* const options = { */
        /* includeScore: true, */
        /* keys: ['name'], */
        /* threshold: 0.2, */
      /* }; */
      
      /* const fuse = new Fuse(this.domain.shadows, options) */
      /* const result = fuse.search(this.shadowSearch) */

      this.shadowSearchResults = this.domain.shadows.filter(item => item.name.includes(this.shadowSearch));

      // this.shadowSearchResults = result.map((r) => r.item);
    },
    handleShadowSearch(e) {
      if (e.key !== "Enter") {
        return;
      }
      
      this.searchShadowDomains(e);
    },
    handleSortChange(e) {
      this.shadowDomainSortBy = e;
    },
    groupShadowDomainsByDomain(shadows) {
      const domainToSubdomains = {};
      const secondPass = [];

      // create all parent keys
      for (let s of shadows) {
        const parsed = psl.parse(s.name);
        const isSubdomain = parsed.input !== parsed.domain;
        if (isSubdomain) {
          secondPass.push(s);
          continue;
        }
        
        domainToSubdomains[parsed.domain] = [];
      }

      // assign subdomains to parent keys, if there is no parent key assign the subdomain as top-level
      for (let s of secondPass) {
        const parsed = psl.parse(s.name);

        if (domainToSubdomains[parsed.domain]) {
          domainToSubdomains[parsed.domain].push(s.name)
          domainToSubdomains[parsed.domain].sort();
        } else {
          domainToSubdomains[s.name] = [];
        }
      }
      
      return domainToSubdomains;
    },
    sortShadowDomainsByCreatedDate(shadows) {
      const sd = [...shadows];

      // oldest to newest
      let compareFn = (a, b) => (a.created > b.created) ? 1 : -1;
      
      if (this.shadowDomainSortBy === SORT_BY_NEWEST_TO_OLDEST) {
        compareFn = (a, b) => (a.created < b.created) ? 1 : -1;
      }
      
      return sd.sort(compareFn);
    },
    handleProxyConfigurationUpdate(update) {
      this.domain.proxy_host = update.proxy_host;
    },
    catchInvalidChars(e) {
      if (e.code === 'Space' || e.code === 'Comma') {
        e.preventDefault();
      }
    }
  }
};
</script>
