<template>
  <div>
    <div v-if="viewMode === viewModes.OVERVIEW">
      <div v-if="!checking && hasDomainStatusData()">
        <h5>{{ $t('message.DomainDiagnoseOverview') }}</h5>
        <p v-if="hasError(this.domainList)">{{ $t('message.batch_diagnose_error_detected') }}</p>
        <p v-else>{{ $t('message.everything_okay') }}</p>
        <CListGroup>
          <CListGroupItem
              class="domain-item d-flex justify-content-between align-items-center"
              v-for="(d, index) in domainList" :key="d.domain"
              @click="viewDomain(index)"
          >
            {{ d.name }}
            <CBadge :color="d.status ? 'success' : 'danger'" shape="pill">{{ d.status ? 'OK' : 'X' }}</CBadge>
          </CListGroupItem>
        </CListGroup>
      </div>
      <CAlert color="info" v-else class="loading-message">
        <CSpinner/>
        <div style="margin-left: 10px;">{{ $t('message.batch_diagnose_in_progress') }}</div>
      </CAlert>
    </div>
    <div v-if="viewMode === viewModes.DOMAIN">
      <div class="domain-mode-navigation">
        <div>
          <CButton @click="handleBackToOverview">{{ $t('message.back_to_overview') }}</CButton>
        </div>

        <nav>
          <ul class="pagination">
            <li :class="{ 'page-item': true, 'disabled': viewDomainIndex === 0 }" @click="prevDomain()">
              <span class="page-link">‹</span>
            </li>
            <li class="page-item">
              <span class="page-link">
                {{ selectedViewDomain.name }}
              </span>
            </li>
            <li :class="{ 'page-item': true, 'disabled': viewDomainIndex === (domainList.length - 1) }" @click="nextDomain()">
              <span class="page-link">›</span>
            </li>
          </ul>
        </nav>
      </div>
      <div class="diagnose-container">
        <h5>{{ $t('message.diagnose_results_domain', { domain: selectedViewDomain.name }) }}</h5>
        <Diagnose :domain="selectedViewDomain" :hideContactUs="true"/>
      </div>
    </div>
  </div>
</template>

<style>
.loading-message {
  display: flex;
  align-items: center;
}

.domain-mode-navigation {
  display: flex;
  align-items: center;
}

.domain-mode-navigation nav {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.domain-mode-navigation .pagination {
  margin-bottom: 0;
}

.domain-mode-navigation .page-link {
  color: gray;
}

.diagnose-container {
  margin-top: 30px;
}

.diagnose-container h5 {
  margin-left: 5px;
}

.domain-item {
  cursor: pointer;
}

.domain-item:hover {
  background-color: #f5f5f5;
}
</style>

<script>
import {isEmpty} from 'lodash';
import {diagnoseDomain} from '@/utilities/api';
import Diagnose from "./Diagnose.vue"

export default {
  name: 'BatchDiagnose',
  props: {
    domains: Array,
    diagnose: Boolean
  },
  components: {Diagnose},
  computed: {
    domainList() {
      if (!this.diagnose)
        return [];

      const domains = [];

      for (let d of this.domains) {
        const domain = {name: d.name};
        domain.status = this.isOkay(this.domainStatusMap[d.name]);

        domains.push(domain);
      }

      return domains;
    },
    selectedViewDomain() {
      return this.domains[this.viewDomainIndex];
    },
    selectedViewDomainStatus() {
      return this.domainStatusMap[this.selectedViewDomain.name];
    }
  },
  mounted() {
    this.diagnoseDomains();
  },
  data() {
    return {
      domainStatusMap: {},
      checking: false,
      viewMode: 'OVERVIEW',
      viewModes: {
        OVERVIEW: 'OVERVIEW',
        DOMAIN: 'DOMAIN'
      },
      viewDomainIndex: null
    };
  },
  methods: {
    hasError(domainList) {
      return domainList.some((d) => !d.status);
    },
    hasDomainStatusData() {
      return !isEmpty(this.domainStatusMap);
    },
    async diagnoseDomains() {
      this.checking = true;

      for (let d of this.domains) {
        const response = await diagnoseDomain(d.name);

        this.domainStatusMap = Object.assign(this.domainStatusMap, {[d.name]: response.data});
      }

      this.checking = false;
    },
    isOkay(statusData) {
      const keys = Object.keys(statusData);

      const isAllPassed = keys.every((k) => {
        const data = statusData[k];

        return data[0] || false;
      });

      return isAllPassed;
    },
    viewDomain(index) {
      this.viewDomainIndex = index;
      this.viewMode = this.viewModes.DOMAIN;
    },
    handleBackToOverview() {
      this.viewMode = this.viewModes.OVERVIEW;
      this.viewDomainIndex = null;
    },
    nextDomain() {
      const topIndex = this.domainList.length - 1;
      if (this.viewDomainIndex < topIndex) {
        this.viewDomainIndex++;
      }
    },
    prevDomain() {
      if (this.viewDomainIndex > 0) {
        this.viewDomainIndex--;
      }
    },
    async refresh() {
      const response = await diagnoseDomain(this.selectedViewDomain.name);

      this.domainStatusMap = Object.assign(this.domainStatusMap, {[this.selectedViewDomain.name]: response.data});
    }
  }
}
</script>
