<template>
  <CListGroup v-if="dg">
    <DgStatus :dgItem="dg.init" :dgName="$t('Initialization')"></DgStatus>
    <DgStatus :dgItem="dg.cname_query" :dgName="$t('domain.CNAMECheck')"></DgStatus>
    <DgStatus :dgItem="dg.cname_visit" :dgName="$t('domain.CNAMEAccess')"></DgStatus>
    <DgStatus :dgItem="dg.source_setup" :dgName="$t('domain.SourceCdnCheck')"></DgStatus>
    <DgStatus :dgItem="dg.source_visit" :dgName="$t('domain.OriginSiteVisitCheck')"></DgStatus>
    <DgStatus :dgItem="dg.source_cname" :dgName="$t('domain.SourceCdnPointingCheck')"></DgStatus>
    <DgStatus :dgItem="dg.ssl_visit" :dgName="$t('domain.OriginSiteHttpsAccessCheck')"></DgStatus>
    <DgStatus :dgItem="dg.site_visit" :dgName="$t('domain.SiteVisitCheck')"></DgStatus>

    <CListGroupItem
      class="d-flex justify-content-between align-items-center"
      >
      <CCard borderColor="white" v-if="dg.source_visit[0]==false">
        <CCardHeader>{{ $t('domain.OriginSiteAccessDetection') }}</CCardHeader>
        <CCardBody>
          <CAlert color="warning">
            <p>{{ $t('domain.DomainName') }}：<strong>{{ domain ? domain.name : '' }}</strong></p>
            <p>{{ $t('Source') }}:  <strong>{{ domain ? domain.sourceConfig.full_url : '' }}</strong></p>
          </CAlert>
          
          <CAlert color="danger" v-if="dg.source_visit[1]=='HTTPS is required'">
            <p>{{ $t('message.cdn_detects_mandatory_https') }}</p>
          </CAlert>
          <CAlert color="danger" v-else>
            <p>{{ $t('domain.SourceDetectionReturnedAnError') }}： {{ dg.source_visit[1] }} </p>
          </CAlert>

          <p class="text-right">
            <CButton color="success" @click="refresh">
            <CIcon name="cil-reload" />
            {{ $t("Refresh")}}
            </CButton>
          </p>
        </CCardBody>
      </CCard>
      <CCard borderColor="white" v-else-if="dg.source_cname[0]==false">
        <CCardHeader>
          <p>{{ $t('message.add_following_cname_then_refresh') }}</p>
          <p>{{ $t('message.cname_operator_message') }}</p>
        </CCardHeader>
        <CCardBody>
          <CAlert color="warning">
            <p>{{ $t('domain.DomainName') }}：<strong>{{ domain ? domain.name : '' }}</strong></p>
            <p v-if="domain && domain.deploy">{{ $t('domain.Cname') }}:  <strong>{{ domain ? domain.deploy.cname : '' }}</strong></p>
          </CAlert>
          <a href='https://wiki.cwcdn.com/public/howto/cname' target='_blank'>{{ $t('ViewConfigurationHelp') }}</a>
          
          <p class="text-right">
            <CButton color="success" @click="refresh">
            <CIcon name="cil-reload" />
            {{ $t("Refresh")}}
            </CButton>
          </p>
        </CCardBody>
      </CCard>
      <CCard borderColor="white" v-else-if="dg.source_visit[0]==false">
        <CCardHeader>{{ $t('domain.OriginCheckError') }}</CCardHeader>
        <CCardBody>
          <CAlert color="warning">
            <p>{{ $t('domain.SourceSiteAccessAbnormal') }}</p>
            <p>{{ $t('message.your_website_does_not_provide_access') }}</p>
            <p>{{ $t('contact_us.contact_service_message') }}</p>
          </CAlert>
          <p class="text-right">
            <CButton color="success" @click="refresh">
            <CIcon name="cil-reload" />
            {{ $t("Refresh")}}
            </CButton>
          </p>
        </CCardBody>
      </CCard>
      
    </CListGroupItem>
    <CListGroupItem v-if="!hideContactUs">
      <strong>{{ $t('contact_us.having_problems_message') }}</strong>
      <Contacts />
    </CListGroupItem>
  </CListGroup>
  <CSpinner v-else />
</template>

<script>
import DgStatus from "./DgStatus.vue"
import Contacts from '@/containers/Contacts'
import {diagnoseDomain} from '@/utilities/api';

export default {
  name: "Diagnose",
  components: {DgStatus, Contacts},
  watch: {
    domain() {
      if (this.isActive) {
        this.refresh();
      }
    }
  },
  data() {
    return {
      config: {},
      dg: null
    };
  },
  props: {
    domain: Object,
    hideContactUs: {
      type: Boolean,
      default: false
    },
    isActive: Boolean
  },
  methods: {
    refresh() {
      this.dg = null;
      if(! this.domain.pk) {
        return
      }

      diagnoseDomain(this.domain.name)
        .then(resp => {
          this.dg = resp.data;
        })
        .catch(function(error) {
          console.log(error);
        });
      }
  },
  mounted() {
    this.refresh()
  }
};
</script>
