<template>
  <div>
    <DomainTrafficAction :domain="domain"/>
  </div>
</template>
<script>
import DomainTrafficAction from "@/views/domain/DomainTrafficAction";
import {MIXED_VALUE} from '@/utilities/constants';

export default {
  components: {DomainTrafficAction},
  props: ['domains'],
  watch: {
    domains: function() {
      this.populateSourceConfig();
    }
  },
  data: function() {
    return {
      domain: {
        sourceConfig: {
          redirect: false,
          full_null: ''
        }
      }
    };
  },
  methods: {
    populateSourceConfig() {
      if (!this.domains.length) 
        return;
      
      const firstDomain = this.domains[0];
      const isSame = this.domains.every((d) => {
        return (
            (firstDomain.sourceConfig.full_url === d.sourceConfig.full_url) &&
            (Boolean(firstDomain.sourceConfig.redirect) === Boolean(d.sourceConfig.redirect))
        )
      });
      
      this.domain = {
        sourceConfig: {
          redirect: isSame ? firstDomain.sourceConfig.redirect : MIXED_VALUE,
          full_url: isSame ? firstDomain.sourceConfig.full_url : MIXED_VALUE
        }
      };
      
      this.domain.sourceConfig.redirect = isSame ? firstDomain.sourceConfig.redirect : MIXED_VALUE;
      this.domain.sourceConfig.full_url = isSame ? firstDomain.sourceConfig.full_url : MIXED_VALUE;
    },
    getDomainSourceConfig() {
      return {...this.domain.sourceConfig};
    }
  }
}
</script>