<template>
  <div>
    <CRow>
      <CCol>
        <h6>{{ $t('domain.DomainAction') }}</h6>
        <p>{{ $t('message.TrafficActionDescription') }}</p>
      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <div class="traffic-actions">
          <div class="traffic-action">
            <div>
              <input @click="handleTrafficActionChange(trafficActionOrigin)"
                     id="origin-traffic-action"
                     type="radio"
                     value="origin"
                     :checked="trafficAction === trafficActionOrigin">
            </div>
            <div>
              <label @click="handleTrafficActionChange(trafficActionOrigin)">{{ $t('domain.RouteToOriginServer') }}</label>
              <p>{{ $t('message.RouteToOriginServerDescription') }}</p>

              <div v-if="trafficAction === trafficActionOrigin" class="traffic-action-input-control">
                <div>
                  <span>{{ $t('message.SpecifyOriginServer') }}</span>
                  <CInput
                      id="specify-origin-server"
                      placeholder="123.12.12.90:8090"
                      v-model="fullUrl"
                      :is-valid="source_validator"
                  ></CInput>
                </div> 

                <CAlert color="warning"
                        v-html="$t('helps.back_to_source_alert')"></CAlert>
              </div>
            </div>
          </div>

          <div class="traffic-action">
            <div>
              <input @click="handleTrafficActionChange(trafficActionRedirect)"
                     id="redirect-traffic-action"
                     type="radio"
                     value="redirect"
                     :checked="trafficAction === trafficActionRedirect">
            </div>
            <div style="flex: 1">
              <label @click="handleTrafficActionChange(trafficActionRedirect)">{{ $t('domain.RedirectTraffic') }}</label>
              <p>{{ $t('message.RedirectTrafficDescription') }}</p>

              <div v-if="trafficAction === trafficActionRedirect" class="traffic-action-input-control">
                <span>{{ $t('message.SpecifyRedirectUrl') }}</span>
                <CInput
                    placeholder="https://www.example.com"
                    v-model="fullUrl"
                    :is-valid="isValidRedirectSource"
                ></CInput>
              </div>
            </div>
          </div>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<style scoped>
.traffic-actions {
  border: solid 1px #d8dbdf;
  border-radius: 3px;
}

.traffic-action {
  display: flex;
  padding: 12px 16px 0 16px;
}

.traffic-action:first-child {
  border-bottom: solid 1px #d8dbdf;
}

.traffic-action > div:first-child {
  padding-top: 1px;
  margin-right: 10px;
}

.traffic-action label {
  font-weight: 500;
  margin-bottom: 0;
}

.traffic-action label + p {
  color: rgba(107, 114, 128, 1);
}
</style>

<script>
import DomainValidationMixin from "@/utilities/DomainValidationMixin";
import {MIXED_VALUE} from '@/utilities/constants';
import validUrl from 'valid-url';

const TRAFFIC_ACTION_ORIGIN = 'origin';
const TRAFFIC_ACTION_REDIRECT = 'redirect';

export default {
  props: ['domain'],
  mixins: [DomainValidationMixin],
  data: function () {
    return {
      trafficActionOrigin: TRAFFIC_ACTION_ORIGIN,
      trafficActionRedirect: TRAFFIC_ACTION_REDIRECT,
    }
  },
  computed: {
    isValidRedirectSource() {
      return Boolean(validUrl.isWebUri(this.fullUrl));
    },
    trafficAction() {
      if (this.isMixedValue(this.domain.sourceConfig.redirect)) {
        return null;
      }

      if (this.domain.sourceConfig.redirect) {
        return this.trafficActionRedirect;
      }

      return this.trafficActionOrigin;
    },
    fullUrl: {
      get() {
        if (this.isMixedValue(this.domain.sourceConfig.full_url)) {
          return '';
        }

        return this.domain.sourceConfig.full_url;
      },
      set(value) {
        this.domain.sourceConfig.full_url = value;
      }
    }, 
  },
  methods: {
    handleTrafficActionChange(action) {
      this.domain.sourceConfig.redirect = (action === this.trafficActionRedirect);
    },
    isMixedValue(value) {
      return value === MIXED_VALUE;
    }
  }
}
</script>
