<template>
  <CListGroupItem
    class="d-flex justify-content-between align-items-center"
    v-if="dgItem"
    >
    {{ dgName }}
    <CBadge :color="getBadge(dgItem)" shape="pill" v-if="dgItem[0]">{{ dgItem[1] }}</CBadge>
    <CBadge :color="getBadge(dgItem)" shape="pill" v-else
    v-c-tooltip="getTooltip(dgItem)"
     >X</CBadge>
  </CListGroupItem>
</template>

<script>
export default {
  name: 'DgStatus',
  data () {
    return {
    }
  },
  props: {
    dgItem: Array,
    dgName: String,
  },
  methods: {
    getBadge(dgitem) {
      return dgitem[0] === true
        ? "success"
        : "danger";
    },
    getTooltip(dgItem) {
      if (dgItem[1]) {
        return {
          content: this.$t(dgItem[1]),
          active: true,
          placement: 'left'
        }
      }
      else {
        return ''
      }
    }
  }
}
</script>