<template>
  <CCard>
    <CCardHeader>
      <CButtonGroup>
        <CButton color="success" variant='outline' @click="domain.cacheConfig.cache_type='nocache'"
                 :pressed="domain.cacheConfig.cache_type==='nocache'">{{$t('no_cache')}}
        </CButton>
        <CButton color="success" variant='outline' @click="domain.cacheConfig.cache_type='static'"
                 :pressed="domain.cacheConfig.cache_type==='static'">{{$t('static')}}
        </CButton>
        <CButton color="success" variant='outline' @click="domain.cacheConfig.cache_type='auto'"
                 :pressed="domain.cacheConfig.cache_type==='auto'">{{$t('automatic')}}
        </CButton>
      </CButtonGroup>
    </CCardHeader>
    <CCardBody v-if="domain.cacheConfig.cache_type==='static'">
      <CAlert color="info">
        <h4>Static cache includes:</h4>
        <dl class="row">
          <dt class="col-sm-3">MediaTypes</dt>
          <dd class="col-sm-9">3u8 ts mp3 mp4 swf</dd>
          <dt class="col-sm-3">Packages</dt>
          <dd class="col-sm-9">jar apk ipa zip rar</dd>
          <dt class="col-sm-3">Images &amp; fonts</dt>
          <dd class="col-sm-9">bmp ico png jpeg jpg gif otf ttf eot webp woff woff2 svg</dd>
          <dt class="col-sm-3">Paths</dt>
          <dd class="col-sm-9">/static /uploads</dd>
        </dl>
      </CAlert>
    </CCardBody>
    <CCardBody v-if="domain.cacheConfig.cache_type==='auto'">
      <CAlert color="info">
        <span v-html="$t('message.cdn_intelligent_caching')"></span>
      </CAlert>
      <CListGroup>
        <h4>{{ $t('domain.TransparentTransmissionRules') }}</h4>

        <CListGroupItem v-for="(rule, index) in this.domain.cacheConfig.nocache" v-bind:key="rule">
          {{ rule }}
          <CButtonClose v-on:click="domain.cacheConfig.nocache.splice(index, 1)"/>
        </CListGroupItem>
        <CListGroupItem class="d-flex justify-content-between align-items-center">
          <div style="width: 100%">
            <CInput
                id="new-rule"
                style="width: 95%"
                v-model="newRuleText"
                :placeholder="$t('domain.EnterTransparentTransmissionRules')+ ': /admin/， ^/auth/'"
            >
            </CInput>
          </div>
          <CBadge size="sm" color="info" v-c-tooltip="{content: $t('helps.strings_or_regex')}"
                  style="margin-right: 8px;">?
          </CBadge>
          <CButton class="close" size="sm" v-on:click="addNewRule" color="black">
            +
          </CButton>
        </CListGroupItem>
      </CListGroup>
    </CCardBody>
    <CAlert color="info margin" v-html="$t('helps.cache_reusable_info')"></CAlert>
    <div class="bad-robots-control m-3">
        <CSwitch
          :checked="iscachereusable"
          @update:checked="handleCacheReusable"
          :color="isMixedValue(iscachereusable) ? 'secondary' : 'success'"
        />
        <span class="ml-2">
          <span v-if="isMixedValue(iscachereusable)">{{ $t('mixed_selection') }}</span>
          <span v-else-if="iscachereusable">{{ $t('Cache Reusable') }}</span>
          <span v-else>{{ $t('Cache Reusable') }}</span>
        </span>
      </div>
  </CCard>
</template>

<script>
export default {
  name: "CacheStrategyControl",
  props: ['domain'],
  data: function() {
    return {
      newRuleText: '',
      iscachereusable: false,
    }
  },
  watch: {
    'domain.cache_reusable': function(newValue) {
      this.iscachereusable = newValue;
    }
  },
  methods: {
    addNewRule: function () {
      if (this.newRuleText.trim() === '') {
        return;
      }

      this.domain.cacheConfig.nocache.push(this.newRuleText.trim())
      this.newRuleText = ''
    },
    isMixedValue(value) {
      return Array.isArray(value) && value.includes('mixed');
    },
    handleCacheReusable(checked) {
      this.iscachereusable = checked;
      this.domain.cache_reusable = this.iscachereusable
    },
  },
};
</script>

<style scoped>
.bad-robots-control {
  display: flex;
  align-items: center;
}
.bad-robots-control-m{
  margin-right: 5px;
}
.margin{
  margin: 0px 1.25rem !important;
}
</style>