<template>
  <div>
    <CSelect
        @change="handleRestrictionTypeChange"
        :value="restrictionType"
        :options="restrictionTypeOptions"
    />

    <p v-if="isMixed()" class="domain-settings-mixed-selection">
      <CIcon name="cil-lightbulb"/>&nbsp;
      <span>{{ $t('message.domain_settings_mixed_selection') }}</span>
    </p>
    <p v-else-if="restrictionType === 'allow'">{{ $t('helps.countries_allowed_only') }}</p>
    <p v-else-if="restrictionType === 'deny'">{{ $t('helps.countries_restricted') }}</p>
    <p v-else>{{ $t('helps.no_country_restriction') }}</p>

    <div :class="{ 'select-country': true, 'is-deny': (restrictionType === 'deny'), 'hidden': isMixed() }">
      <multiselect v-model="selectedCountries"
                   :options="countryList"
                   label="name"
                   track-by="code"
                   :placeholder="$t('helps.placeholder_select')"
                   :multiple="true"
                   :disabled="restrictionType == null"
                   :close-on-select="false"></multiselect>
    </div>
    <div class="combine-geo-config">
      <input type="checkbox" v-if="this.isBatchEdit" v-model="combine_geo_config" id="overrideCheckbox" />
      <label v-if="this.isBatchEdit" class="combine-geo-config-label" for="overrideCheckbox">{{ $t('domain.CombineGeoConfig') }}</label>
    </div>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.hidden {
  opacity: 0;
}

.domain-settings-mixed-selection {
  font-size: 0.9em;
  color: #825d17;
}
</style>

<!--this style for the multiselect country select should not be scoped or they wont work-->
<style>
.select-country.is-deny .multiselect__tag {
  background-color: #e55353;
}

.combine-geo-config {
  margin-top: 15px;
}

.combine-geo-config-label {
  margin-left: 6px;
}

.select-country.is-deny .multiselect__tag .multiselect__tag-icon:hover {
  background-color: #993232;
}
</style>

<script>
import {isArray} from 'lodash';
import Multiselect from 'vue-multiselect';
import countriesJson from '@/views/domain/CountrySelector/countries.json';

export default {
  name: 'CountrySelector',
  components: {Multiselect},
  props: ['countryRestrictionData', 'mixedValueToken', 'isBatchEdit'],
  computed: {
    restrictionTypeOptions: function () {
      const options = [
        {value: null, label: this.$t('disabled')},
        {value: 'allow', label: this.$t('allow')},
        {value: 'deny', label: this.$t('deny')}
      ];

      if (this.isMixed()) {
        options.push({value: this.mixedValueToken, label: this.$t('mixed_selection')})
      }

      return options;
    },
    countriesMap: function () {
      const map = {};

      for (let c of this.countryList)
        map[c.code] = c;

      return map;
    },
    restrictionType: function () {
      if (this.isMixed()) {
        return this.mixedValueToken;
      }

      if (this.countryRestrictionData && this.countryRestrictionData[0])
        return this.countryRestrictionData[0];

      return null;
    },
    selectedCountries: {
      get() {
        if (this.isMixed())
          return [];

        if (this.countryRestrictionData && this.countryRestrictionData[1]) {
          return this.countryRestrictionData[1].map((countryCode) => this.countriesMap[countryCode]);
        }

        return [];
      },
      set(selections) { 
        let countries = selections.map((s) => s.code);
        if (countries.indexOf('all') >= 0) {
          if (this.countryRestrictionData[1].length === 0) {
            let allCountry = Object.keys(this.countriesMap);
            allCountry = allCountry.filter(item => item !== 'all');
            countries = allCountry;
          } else {
            countries = [];
          }
        }
        
        if (this.countryRestrictionData[1].length === this.countryList.length - 1 && this.countryRestrictionData[1].length === selections.length) {
          countries = [];
        }

        this.$emit('change', [this.restrictionType, countries, {override_whitelist: this.combine_geo_config}]);
      }
    }
  },
  data() {
    return {
      countryList: countriesJson,
      combine_geo_config :false
    }
  },
  methods: {
    handleRestrictionTypeChange(e) {
      const value = e.target.value;

      if (!value.length) {
        this.$emit('change', []);
      } else {
        const countries = isArray(this.countryRestrictionData) && this.countryRestrictionData[1] ? this.countryRestrictionData[1] : [];

        this.$emit('change', [value, countries]);
      }
    },
    isMixed() {
      return this.countryRestrictionData === this.mixedValueToken;
    }
  }
}
</script>
