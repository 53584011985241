<template>
  <div>
    <CButtonGroup>
      <CButton :pressed="option === options.none"
               @click="handleNoneClicked"
               color="success" variant="outline">{{ $t('None') }}
      </CButton>
      <CButton :pressed="option === options.proxy_host"
               @click="option = options.proxy_host"
               color="success" variant="outline">{{ $t('domain.ProxyHost') }}
      </CButton>
    </CButtonGroup>

    <div v-if="option === options.proxy_host" style="margin-top: 16px">
      <CInput
          :label="$t('domain.ProxyHostUrl')"
          :is-valid="domain_validator"
          :value="domain.proxy_host"
          :placeholder="$t('message.EnterProxyHostUrl')"
          @change="handleProxyHostUrlChange"
      />
      <p class="text-muted mt-n3" v-html="$t('message.DomainInputDescription')"/>
    </div>
  </div>
</template>

<script>
import DomainValidationMixin from '@/utilities/DomainValidationMixin';

const OPTION_NONE = 'none';
const OPTION_PROXY_HOST = 'proxy_host';

export default {
  props: ['domain'],
  mixins: [DomainValidationMixin],
  data() {
    return {
      option: this.domain.proxy_host ? OPTION_PROXY_HOST : OPTION_NONE,
      options: {
        none: OPTION_NONE,
        proxy_host: OPTION_PROXY_HOST
      }
    };
  },
  methods: {
    handleProxyHostUrlChange(value) {
      if (!this.domain_validator(value))
        return;

      this.$emit('update', {proxy_host: value});
    },
    handleNoneClicked() {
      this.option = this.options.none;
      
      this.$emit('update', {proxy_host: null});
    },
  },
}
</script>