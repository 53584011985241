<template>
  <CModal color="warning"
          :show.sync="mutatedIsVisible"
          id="sslModal"
          :close-on-backdrop="false"
          size="lg">
    <template slot="header">
      <h5 class="modal-title">{{ dialogTitle }}</h5>
    </template>

    <div class="ssl-config-container" v-if="showMainPanel">
      <CRow>
        <CCol lg="6">
          <header>
            <p class="domain-label">{{ $t('message.SSLConfigurationFor') }}</p>
            <h1>{{ domain.name }}</h1>
          </header>
          <div>
            <CButton v-if="hasSslCert(domain)"
                     variant="ghost"
                     class="show-cert-information-message"
                     size="sm"
                     @click="toggleCertView(domain)">
              <span v-if="isViewedCert(domain)">{{ $t('message.HideCertInfo') }}</span>
              <span v-else>{{ $t('message.ShowCertInfo') }}</span>
            </CButton>
          </div>
        </CCol>
        <CCol lg="6">
          <div class="action-area">
            <div>
            <span class="generating-cert" v-if="isPendingGeneration(domain)">
              <CSpinner size="sm"/>{{ $t('message.GeneratingCertificate') }}
            </span>
              <span class="https-enabled" v-else-if="hasSslCert(domain)">{{ $t('message.CertificateIssued') }}</span>
              <span class="error-issue-status"
                    v-else-if="isFailedGeneration(domain)">{{ $t('message.ErrorCertificateGeneration') }}</span>
              <span class="no-https-configured" v-else>{{ $t('message.HTTPSNotEnabledForDomain') }}</span>
            </div>
            <div v-if="hasSslCert(domain)">
              <CInputCheckbox :label="$t('domain.MandatorySSL')" :checked.sync="domain.sslConfig.forceSSL"
                              @change="patchConfig(false)"/>
            </div>
            <div style="margin-top: 25px;">
              <CertActionControls :domain="domain"
                                  :disabled="domain.isGenerating"
                                  :issue-cert-type="issueCertType"
                                  :upload-cert="showUploadDomainCert"
                                  :click-cert-type="clickCertType"
                                  :click-remove-cert="clickRemoveCert"
                                  :has-ssl-cert="hasSslCert"
                                  :show-remove-cert-button="showRemoveCertButton"/>
            </div>
          </div>
        </CCol>
      </CRow>

      <CRow v-if="domain._sslConfig && isViewedCert(domain)">
        <CCol lg="12">
          <div class="cert-information">
            <h6>
              <CIcon name="cil-file"/>
              {{ $t('cert.Information') }}
            </h6>
            <CertInformation :cert="domain._sslConfig" :key="domain.pk"/>
          </div>
        </CCol>
      </CRow>

      <CRow>
        <CCol lg="12">
          <div class="shadow-domains-section">
            <CRow>
              <CCol>
                <h2>{{ $t('domain.ShadowDomain') }}</h2>
                <p v-if="domain.shadows.length">{{ $t('message.ConfigureShadowDomainSSL') }}</p>
              </CCol>
              <CCol class="text-right">
                <CButton variant='outline' color='primary' @click="$emit('auto-generate', domain)"
                         v-if="domain.shadows.length > 0 && domain.sslConfig.cert_type != 'TXT'">
                  <CIcon name="cil-settings"/>
                  {{ $t('AutoGenerateCert') }}
                </CButton>
              </CCol>
            </CRow>

            <div>
              <div v-if="domain.shadows.length">
                <div>
                  <CInput
                      type="text"
                      :placeholder="$t('message.SearchShadowDomain')"
                      size="sm"
                      v-model="shadowSearch"
                      @keypress="handleShadowSearch"
                  >
                    <template #append>
                      <CButton type="submit" color="primary" @click="search">{{ $t('Search') }}</CButton>
                    </template>
                  </CInput>
                </div>
                <CListGroup>
                  <CListGroupItem v-for="d in this.pagerPagedData" v-bind:key="d.pk">
                    <div class="shadow-domain-item">
                      <div class="shadow-domain-item-header">
                        <h3>{{ d.name }}</h3>
                        <CAlert color="warning" class="alert-shadow-domain" closeButton :show="!!d.error"
                                @update:show="d.error = null">{{ d.error }}
                        </CAlert>
                        <CButton v-if="hasSslCert(d)"
                                 variant="ghost"
                                 class="show-cert-information-message"
                                 size="sm"
                                 @click="toggleCertView(d)">
                          <span v-if="isViewedCert(d)">{{ $t('message.HideCertInfo') }}</span>
                          <span v-else>{{ $t('message.ShowCertInfo') }}</span>
                        </CButton>
                      </div>
                      <div class="action-area">
                        <div>
                          <span class="generating-cert" v-if="isPendingGeneration(d)">
                            <CSpinner size="sm"/>{{ $t('message.GeneratingCertificate') }}
                          </span>
                          <span class="generating-cert" v-else-if="isPendingGenerationShadow(d)">
                            <CSpinner size="sm"/>{{ $t('message.GeneratingCertificate') }}
                          </span>
                          <span class="certificate-issued" v-else-if="hasSslCert(d)">
                            {{ $t('message.CertificateIssued') }}
                          </span>
                          <span class="error-issue-status"
                                v-else-if="isFailedGeneration(d)">{{ $t('message.ErrorCertificateGeneration') }}</span>
                          <span class="error-issue-status" v-else-if="d.name.startsWith('*.')">
                                {{ $t('message.HTTPSNotEnabledForDomain') }}</span>
                          <span class="inheriting-parent-domain" v-else>{{
                              $t('message.InheritingParentDomain')
                            }}</span>
                        </div>
                        <CertActionControls :domain="d"
                                            :main-domain="domain"
                                            :disabled="d.isGenerating"
                                            :issue-cert-type="issueCertType"
                                            :upload-cert="showUploadDomainCert"
                                            :click-cert-type="clickCertType"
                                            :click-remove-cert="clickRemoveCert"
                                            :has-ssl-cert="hasSslCert"
                                            :show-remove-cert-button="showRemoveCertButton"/>
                      </div>
                    </div>
                    <div v-if="d._sslConfig && isViewedCert(d)" class="cert-information compact">
                      <h6>
                        <CIcon name="cil-file"/>
                        {{ $t('cert.Information') }}
                      </h6>
                      <CertInformation :cert="d._sslConfig" :key="d.pk"/>
                    </div>
                  </CListGroupItem>
                </CListGroup>
                <CPagination
                    :activePage.sync="pagerCurrentPage"
                    :pages="pagerTotalPages"
                    size="sm"
                    align="end"
                    class="list-pager"
                />
              </div>
              <div class="no-shadow-domain-placeholder" v-else>
                <p>{{ $t('message.NoConfiguredShadowDomains', [domain.name]) }}</p>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </div>
    <div v-else class="upload-cert-container">
      <CTextarea
          :label="$t('helps.EnterDigitalCertificateIncludesTwoCertificate')"
          placeholder="-----BEGIN CERTIFICATE-----
MIIFWTCCBEGgAwIB....
-----END CERTIFICATE-----
-----BEGIN CERTIFICATE-----
.....
MIIFWTCCBEGgAwIBAgISA434XVgVXX
-----END CERTIFICATE-----"
          horizontal
          rows="15"
          v-model="uploadDomainCert.sslConfig.certificate"
      />

      <CTextarea
          :label="$t('helps.EnterEncryptionKey')"
          placeholder="-----BEGIN RSA PRIVATE KEY-----
MIIEpAIBAAK....
-----END RSA PRIVATE KEY-----"
          horizontal
          rows="15"
          v-model="uploadDomainCert.sslConfig.privateKey"
      />
    </div>

    <template slot="footer-wrapper">
      <footer class="modal-footer" v-if="showMainPanel">
        <CButton @click="cancelCert()">{{ $t('Cancel') }}</CButton>
        <CButton color="primary" type="submit" @click="patchConfig" :disabled="saving">
          <span v-if="saving">
            <CSpinner size="sm"/>
            {{ $t('Saving') }}
          </span>
          <span v-else>
            {{ $t('Save') }}
          </span>
        </CButton>
      </footer>
      <footer class="modal-footer" v-else>
        <CButton @click="uploadDomainCert = null">{{ $t('Back') }}</CButton>
        <CButton color="primary" type="submit" @click="saveCert(uploadDomainCert)"
                 :disabled="saving || !hasCertificateValues">
          <span v-if="saving">
            <CSpinner size="sm"/>
            {{ $t('Saving') }}
          </span>
          <span v-else>
            {{ $t('Save Cert') }}
          </span>
        </CButton>
      </footer>
    </template>
  </CModal>
</template>

<style scoped>
.ssl-config-container, .upload-cert-container {
  padding: 20px;
}

.ssl-config-container .domain-label {
  font-weight: normal;
  margin-bottom: 0;
  margin-top: 5px;
}

.list-pager {
  margin-top: 6px;
}

.ssl-config-container h1 {
  font-size: 1.125rem;
  margin-bottom: 0;
}

.show-cert-information-message {
  color: gray;
}

.action-area {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.generating-cert {
  color: #4d97f2;
}

.generating-cert .spinner-border {
  margin-right: 6px;
}

.https-enabled, .no-https-configured {
  font-size: 1rem;
}

.https-enabled, .certificate-issued {
  color: #2eb85c;
}

.error-issue-status {
  color: #d55d58;
}

.inheriting-parent-domain {
  color: #4d97f3;
}

.no-https-configured {
  color: #f9b115;
}

.cert-information {
  border: rgba(0, 0, 21, 0.125) 1px solid;
  padding: 16px;
  border-radius: 4px;
}

.cert-information.compact {
  border-top: rgba(0, 0, 21, 0.125) 1px solid;
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-radius: 0;
  margin-top: 12px;
}

.cert-information h6 {
  color: #4d97f3;
  font-weight: 500;
}

.cert-information h6 svg {
  margin-right: 4px;
}

.shadow-domains-section {
  margin-top: 40px;
}

.shadow-domains-section h2 {
  font-size: 1.15em;
  margin-bottom: 0;
}

.shadow-domain-item {
  display: flex;
  align-items: center;
}

.shadow-domain-item h3 {
  font-size: 1em;
  margin-bottom: 0;
}

.shadow-domain-item-header {
  flex: 1;
}

.no-shadow-domain-placeholder {
  margin-top: 10px;
  text-align: center;
  color: gray;
  padding: 20px;
  border-width: 2px;
  border-style: dashed;
  border-color: #c5c5c5;
  border-radius: 4px;
}

.no-shadow-domain-placeholder p {
  margin: 0;
}

.modal-footer {
  padding-left: 30px;
  padding-right: 30px;
}

</style>

<style>
.alert-shadow-domain {
  padding-right: 35px;
  width: auto;
  display: inline-block;
}

.alert-shadow-domain.close {
  padding: 6px;
}
</style>

<script>
import axios from "@/plugins/axios.js";
import {getDomain} from '@/utilities/api';
import CertActionControls from "@/views/domain/CertActionControls";
import CertInformation from "@/views/domain/CertInformation";
import {ShadowDomainAction} from '@/utilities/websocket';
import Fuse from 'fuse.js'

const SHADOW_DOMAINS_PER_PAGE = 10;

export default {
  name: "SslConfig",
  components: {CertActionControls, CertInformation},
  watch: {
    isVisible () {
      this.mutatedIsVisible = this.isVisible
      if (this.mutatedIsVisible) {
        this.initWs()
      }
    },
    mutatedIsVisible() {
      this.$emit('update:isVisible', this.mutatedIsVisible)
    },
    domain: {
      handler: function() {
        this.config = this.domain ? {...this.domain.sslConfig} : {};
        if ("certifacate" in this.config) {
          this.config.certificate = this.config.certifacate
        }
        this.issueTasks = []
        this.certType = null
      },
      deep: true,
    }
  },

  data() {
    return {
      config: {},
      renderConfig: 1,
      errors: [],
      ssection: "https",
      certType: "",
      saveCertType: "",
      labelText: {
        labelOn: "yes",
        labelOff: "no"
      },
      certDomain: {},
      labelForceSSL: {
        labelOn: "强制",
        labelOff: "强制"
      },
      issueTasks: [],
      issueTaskState_: "",
      generatingDomainCert: null,
      generationActive: false,
      saving: false,
      uploadDomainCert: null,
      pagerCurrentPage: 1,
      mutatedIsVisible: false,
      shadowSearch: "",
      shadowSearchResults: null,
      removeCert: false
    };
  },
  props: {
    domain: Object,
    is_root: Boolean,
    isVisible: Boolean
  },
  computed: {
    shadow_ws: {
      get() {
        return this.$store.state.websocket.shadow_ws;
      },
      set(value) {
        this.$store.state.websocket.shadow_ws = value;
      }
    },
    issueWs() {
      return this.$store.state.websocket.issue_ws;
    },
    dialogTitle: function () {
      if (this.showMainPanel)
        return this.$t('domain.ConfigureSSL') + ': ' + this.domain.name;

      return this.$t('cert.UploadCertificate') + ': ' + this.uploadDomainCert.name;
    },
    useSearchResults() {
      return Boolean(this.shadowSearchResults);
    },
    pagerPagedData: function () {
      if (!this.domain.shadows.length)
        return [];

      const zeroIndexedPage = this.pagerCurrentPage - 1;
      const start = zeroIndexedPage * SHADOW_DOMAINS_PER_PAGE;
      const end = (start + SHADOW_DOMAINS_PER_PAGE);
      
      if (this.useSearchResults) {
        return this.shadowSearchResults.slice(start, end);
      }
      
      return this.domain.shadows.slice(start, end);  
    },
    pagerTotalPages: function () {
      if (this.useSearchResults) 
        return Math.ceil(this.shadowSearchResults.length / SHADOW_DOMAINS_PER_PAGE);
      
      if (!this.domain.shadows.length)
        return 0;

      return Math.ceil(this.domain.shadows.length / SHADOW_DOMAINS_PER_PAGE);
    },
    issueTaskState: function () {
      let self = this
      if (this.issueTasks.length == 0) {
        ;
      } else if (this.issueTasks.some((task) => task.state == 'FAILURE')) {
        self.issueTaskState_ = 'FAILURE';
      }
      if (this.issueTasks.every((task) => task.state == 'SUCCESS')) {
        self.issueTaskState_ = 'SUCCESS';
      }
      if (this.issueTasks.some((task) => task.state == 'PENDING')) {
        self.issueTaskState_ = 'PENDING';
      }
      return self.issueTaskState_
    },
    showMainPanel: function () {
      return !Boolean(this.uploadDomainCert);
    },
    hasCertificateValues: function () {
      if (this.uploadDomainCert) {
        if (this.uploadDomainCert.sslConfig.privateKey && this.uploadDomainCert.sslConfig.certificate) {
          return true;
        }
      }

      return false;
    }
  },
  //update:activeTab
  methods: {
    isViewedCert(domain) {
      if (domain.sslConfig.hasOwnProperty('is_show')) {
        return domain.sslConfig.is_show;
      }
      return false;
    },
    toggleCertView(domain) {
      if (this.isViewedCert(domain)) {
        domain.sslConfig.is_show = false;
        this.$forceUpdate();
      } else { 
        domain.sslConfig.is_show = true;
      }

      if (domain.sslConfig.is_show) {
        domain.sslConfig.is_show = false;
        this.fetchCert(domain);
      }
    },
    showUploadDomainCert(uploadDomainCert) {
      if (uploadDomainCert.status == 'disabled') {
        this.flash(this.$t('message.RestoreDoaminToGenerateCert'), "error", {"timeout": 3000});
        return;
      } else {
        this.uploadDomainCert = uploadDomainCert;
      }
    },
    activeSslConfig(dom) {
      this.certType = ""
      this.fetchCert(dom)
    },
    fetchCert(domain) {
      const sslConfig = domain.sslConfig
      if (sslConfig.cert_id) {
        axios({
          method: "GET",
          url: `cert/issue/{cert}/`,
          urlParams: {
            cert: sslConfig.cert_id
          }
        })
            .then(async (response) => {
              domain.sslConfig.is_show = true;
              domain._sslConfig = response.data;
              this.$forceUpdate();
            })
            .catch(error => console.log(error));
      }
    },
    cancelCert() {
      this.saveCertType = "";
      this.certDomain = {};
      this.removeCert = false
      this.$emit('hide');
    },
    // startUpload(val) {
    //   if (val) {
    //     this.domain.sslConfig.cert_type='UPLOAD'
    //   }
    //   else {
    //     this.domain.sslConfig.cert_type=''
    //   }
    // },
    getIssueState(tasklists) {
      axios
          .post("domain/tasks/status/", {
            tasks: tasklists
          })
          .then(async (response) => {
            this.issueTasks = response.data;
            if (this.issueTaskState == "SUCCESS") {
              clearInterval(this.issuePolling)
              this.issuePolling = null;
              //const forceSSL = this.config.forceSSL;
              /**
               * When task has finished, the cert status is saved.
               * So fetch the status from server again.
               */
              let responseDomain;
              try {
                responseDomain = await getDomain(this.domain.name);
              } catch (errors) {
                errors.forEach((message) => {
                  this.flash(message, 'error', {"timeout": 15000});
                });
                return;
              }
              this.domain = responseDomain.data;
              this.$forceUpdate();

              this.generateStatus(this.domain, false);

              //var cert_id = 1
              // restore sslConfig.forceSSL
              // wrapped in setTimeout to make it be the end of the event loop, hopefully
              // Vue has completed executing its reconciliation
              // setTimeout(() => {
              //     this.domain.sslConfig.forceSSL = forceSSL;
              //     this.domain.sslConfig.cert_id = cert_id;
              // }, 1);
            } else if (this.issueTaskState == "FAILURE") {
              clearInterval(this.issuePolling)
              this.issuePolling = null;

              this.flash(this.$t("Issue cert error"), 'error', {"timeout": 15000});

              this.generateStatus(this.domain, false);
            }
          })
          .catch(error => console.log(error));
    },
    updateIssueState(tasklists) {
      clearInterval(this.issuePolling)
      this.issuePolling = null;
      this.issuePolling = setInterval(() => {
        this.getIssueState(tasklists);
      }, 5000);
    },

    switchEnable(val) {
      if (val == false) {
        this.certType = ""
      }
    },

    activeT() {
      this.$refs.domainTabs;
      this.domain.sslConfig.enabled && (this.$refs.domainTabs.activeTab = 0);
    },

    clickCertType(domain, certType) {
      this.renderConfig += 1;
      this.certType = certType
      domain.sslConfig.cert_type = certType;
    },
    clickRemoveCert(domain) {
      this.renderConfig +1;
      domain.sslConfig = {'enabled': false, 'forceSSL': false, 'privateKey': '', 'cert': '', 'cert_id': null, 'issue_state': null, 'cert_type': null};
      this.removeCert = true
    },
    generateStatus(domain, active = true) {
      this.generatingDomainCert = domain;
      this.generationActive = active;
    },
    hasActiveGeneration() {
      if (this.generatingDomainCert) {
        return this.generationActive;
      }

      return false;
    },
    isFailedGeneration(domain) {
      return domain.hasOwnProperty('taskState') && domain.taskState === 'FAILURE';
    },
    isPendingGeneration(domain) {
      return domain.hasOwnProperty('isGenerating') && domain.isGenerating;
    },
    isPendingGenerationShadow(domain) {
      if (domain.deploy_status === 'running' && this.domain.sslConfig.hasOwnProperty('enabled') && this.domain.sslConfig.enabled) {
        return true;
      }
      return false;
    },
    initWs() {
      this.shadow_ws.onMessage = function(event, result) {
        const shadow_updated = result.data.shadow;
        const shadow = this.domain.shadows.find(item => item.name === shadow_updated.name);
        if (result.action === ShadowDomainAction.CNAME_CHECK_ERROR) {
          this.$set(shadow, 'sslConfig', shadow_updated.sslConfig);
          this.$set(shadow, 'deploy_status', shadow_updated.deploy_status);
          this.$set(shadow, 'error', result.data.error);
          return
        }
        this.$set(shadow, 'sslConfig', shadow_updated.sslConfig)
        this.$set(shadow, 'deploy_status', shadow_updated.deploy_status);
        this.$set(shadow, 'error', result.data.error);
      }.bind(this)

      this.issueWs.onMessage = function (event, data) {

        if (data.data.error) {
          const cert_issue = data.data.cert_issue;
          let domain = this.domain;
          if (domain.name !== cert_issue.name) {
            domain = this.domain.shadows.find(item => item.name === cert_issue.name);
          }

          if (data.data.error.cert_issues) {
            for (let field in data.data.error.cert_issues[0]) {
              const msg = data.data.error.cert_issues[0][field].pop();
              this.flash(field + ': ' + msg, "error", {"timeout": 3000});
            }
          } else if (data.data.error) {
            data.data.error = data.data.error.replaceAll("*.", "");
            if (cert_issue.hasOwnProperty('model_name') && cert_issue.model_name.toLowerCase() === 'shadowdomain') {
              domain.error = data.data.error;
            } else {
              this.flash(data.data.error, "error", {"timeout": 3000});
            }
          } else {
            this.flash(this.$t('message.ErrorCertificateGeneration'), "error", {"timeout": 3000});
          }

          domain.isGenerating = false;
          return;
        }

        const dp = data.data.dp;
        const cert_issue = data.data.cert_issue;
        let domain = this.domain;

        if (domain.name !== cert_issue.name) {
          domain = this.domain.shadows.find(item => item.name === cert_issue.name);
        }
        if (dp.success) {
          const taskResult = dp.result;

          domain.sslConfig['enabled'] = true;
          domain.sslConfig['cert_type'] = taskResult.cert_type;
          domain.sslConfig['cert_id'] = taskResult.cert_id;
          domain.sslConfig['issue_state'] = taskResult.issue_state;
          domain.isGenerating = false;
          this.$set(domain, 'taskState', dp.state);
        } else {
          if (domain && dp.state !== null) {
            this.$set(domain, 'taskState', dp.state);
          }
        }
      }.bind(this);
    },
    issueCertType(domain, certType) {
      this.issueTasks = []
      this.certType = certType
      this.certDomain = domain
      this.saveCertType = certType
      
      if ((certType == "HTTP" && domain.name.length >= 64)) {
        this.flash(this.$t('message.UseDifferentCertMethod'), "error", {"timeout": 3000});
        return;
      }
      
      if (certType == "HTTP" || certType == "DNS") {
        if (!this.issueWs || !this.issueWs.isOpen()) {
          this.flash(this.$t('message.ServerConnectionNotEstablished'), "error", {"timeout": 3000});
          return;
        }

      if (domain.status == 'disabled') {
        this.flash(this.$t('message.RestoreDoaminToGenerateCert'), "error", {"timeout": 3000});
        return;
      }

        this.$set(domain, 'isGenerating', true)
        const issueAction = 1;
        this.issueWs.sendMessage(issueAction, {
          'cert_issues': [{
            'cert_type': certType,
            'name': domain.name,
            'cname': this.domain.cname,
            'model_name': domain.model_name ? domain.model_name : 'shadowdomain'
          }],
        });
      }
    },
    saveCert(domain) {
      if (!this.hasCertificateValues) {
        return;
      }

      if (domain.sslConfig.cert_type == "TXT") {
        domain.sslConfig.certificate = domain.sslConfig.certificate.trim();
        domain.sslConfig.privateKey = domain.sslConfig.privateKey.trim();

        if (domain.sslConfig.certificate == "") {
          this.flash(this.$t('message.empty_digital_cert_error'), "error", {"timeout": 3000});
          return
        } else if (!(domain.sslConfig.certificate.startsWith("-----BEGIN CERTIFICATE-----")
            && domain.sslConfig.certificate.endsWith("-----END CERTIFICATE-----"))) {
          this.flash(this.$t('message.incorrect_digital_certification_format'), "error", {"timeout": 3000});
          return
        } else {
          let count = (domain.sslConfig.certificate.match(/-----(BEGIN|END) CERTIFICATE-----/g) || []).length;
          if (count < 4) {
            this.flash(this.$t('message.digital_certification_error'), "error", {"timeout": 3000});
            return
          }
        }

        if (domain.sslConfig.privateKey == "") {
          this.flash(this.$t('message.empty_private_key_error'), "error", {"timeout": 3000});
          return
        }
            // else if (! (this.config.privateKey.startsWith("-----BEGIN RSA PRIVATE KEY-----")
            //    && this.config.privateKey.endsWith("-----END RSA PRIVATE KEY-----"))) {
            //   this.flash("私鑰格式錯誤，應該以-----BEGIN RSA PRIVATE KEY-----開頭，-----END RSA PRIVATE KEY-----結尾", "error", { "timeout": 3000 });
            //   return
        // }
        else {
          let count = (domain.sslConfig.privateKey.match(/-----(BEGIN|END) (RSA|EC)? ?PRIVATE KEY-----/g) || []).length;
          if (count < 2) {
            this.flash(this.$t('message.incorrect_private_key_format'), "error", {"timeout": 3000});
            return
          }
        }
      }

      this.saving = true;

      axios({
        method: "POST",
        url: `cert/issue/upload/`,
        data: {
          "cert_type": 'TXT',
          "name": domain.name,
          "model_name": domain.model_name,
          "aliases": domain.aliases,
          "cname": domain.cname,
          "certificate": domain.sslConfig.certificate,
          "privateKey": domain.sslConfig.privateKey,
        },
        urlParams: {}
      })
          .then(response => {
            this.saving = false;

            this.activeSslConfig(domain)
            this.$emit("hide");

            this.uploadDomainCert = null;
          })
          .catch(errors => {
            this.saving = false;

            errors.forEach((message) => {
              this.flash(this.$t(message), 'error', {"timeout": 5000});
            });
          });
    },
    patchSslConfig(domain) {
      if (domain.model_name == "Domain") {
        this.patchConfig(false)
      } else {
        this.patchShadowConfig(domain)
      }
    },
    patchShadowConfig(shadow) {
      axios({
        method: "PATCH",
        url: `domain/shadow/{shadow}/`,
        data: {
          sslConfig: shadow.sslConfig,
        },
        urlParams: {
          shadow: shadow.pk
        }
      })
    },
    patchConfig(auto_close = true) {
      if (this.saving)
        return;

      if (this.domain.sslConfig.cert_type === null || (this.domain.sslConfig.cert_type === "" && this.removeCert)) {
        this.domain.sslConfig.enabled = false;
        this.domain.sslConfig.forceSSL = false
        this.domain.sslConfig.HTTP2 = true
        this.removeCert = false
      } else {
        this.domain.sslConfig.enabled = true;
        if (!this.domain.sslConfig.cert_id) {
          let shadowHasCert = false;
          let showMessage = true;
          this.domain.shadows.forEach(shadow => {
            if (shadow.sslConfig && shadow.sslConfig.cert_id) {
              shadowHasCert = true;
              showMessage = false;
              this.flash(this.$t('message.main_domain_cert_error'), "error", { "timeout": 3000 });
              shouldSave = false;
              return;
            }
          });
          if (!shadowHasCert && showMessage) {
            this.flash(this.$t('message.ssl_not_configured_with_cert'), "error", { "timeout": 3000 });
            this.domain.sslConfig.enabled = false;
            shouldSave = false;
            return;
          }
        }
      }

      this.saving = true;
      this.domain.shadows.forEach(item => {
        if (item.sslConfig.hasOwnProperty('enabled') && item.sslConfig.enabled) {
          item.sslConfig['forceSSL'] = this.domain.sslConfig.forceSSL;
        }
        if (!item.sslConfig.hasOwnProperty('cert_id')) {
          item.sslConfig = {};
        }
      })

      axios({
        method: "POST",
        url: `domain/{domain}/config/`,
        data: {
          section: "sslConfig",
          config: this.domain.sslConfig,
          shadows: this.domain.shadows
        },
        urlParams: {
          domain: this.domain.name
        }
      })
          .then(response => {
            this.saving = false;

            this.flash(this.$t("action.success"), "success", {timeout: 3000});
            if (auto_close) {
              this.$emit("hide");
            }
          })
          .catch(errors => {
            this.saving = false;

            errors.forEach((message) => {
              this.flash(message, 'error', {"timeout": 5000});
            });
          });
    },
    hasSslCert(domain) {
      if (this.domain.pk === domain.pk) {
        return this.domain.sslConfig && this.domain.sslConfig.enabled && this.domain.sslConfig.cert_type && this.domain.sslConfig.cert_type !== '';
      }

      return domain.sslConfig && domain.sslConfig.enabled && domain.sslConfig.cert_type && domain.sslConfig.issue_state && (domain.sslConfig.issue_state === 'issued'); //domain.sslConfig.enabled . shodow sslconfig may havenot an enable field
    },
    showRemoveCertButton(domain) {
      if (this.domain.pk === domain.pk) {
        return true;
      } else if (domain.sslConfig.forceSSL) {
          return false;
      }
      return true;
    },    
    isCertError(domain) {
      return domain.sslConfig && domain.sslConfig.issue_state && domain.sslConfig.issue_state === 'error';
    },
    search() {
      if (!this.shadowSearch.length) {
        this.shadowSearchResults = null;
        return;
      }
      /* const options = { */
        /* includeScore: true, */
        /* keys: ['name'], */
        /* threshold: 0.2 */
      /* }; */
      
      /* const fuse = new Fuse(this.domain.shadows, options) */
      /* const result = fuse.search(this.shadowSearch) */


      // this.shadowSearchResults = result.map((r) => r.item);

      this.shadowSearchResults = this.domain.shadows.filter(item => item.name.includes(this.shadowSearch));
      this.pagerCurrentPage = 1;
    },
    handleShadowSearch(e) {
      if (e.key !== "Enter") {
        return;
      }
      
      this.search();
    }
  }
};
</script>
