<template>
  <div class="top">
    <CCard style="margin-bottom: 5px">
      <CCardBody>
        <GuardConfiguration :guard-config="guardConfig"
                            :isBatchEdit="this.isBatchEdit"
                            ref="guardConfiguration"></GuardConfiguration>
      </CCardBody>
    </CCard>
    <div class="reset-btn-container">
      <CButton color="link" @click="handleReset">{{ $t('message.reset_security_settings') }}</CButton>
    </div>
  </div>
</template>

<style scoped>
.top > .card:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.reset-btn-container {
  text-align: right;
}
</style>

<script>
import GuardConfiguration, {guardConfigDefaultFieldValues} from "@/views/domain/GuardConfiguration";
import {isEqual} from "lodash";
import {MIXED_VALUE} from '@/utilities/constants';

export default {
  name: "MultiDomainGuardConfiguration",
  components: {GuardConfiguration},
  props: ['domains', 'isBatchEdit'],
  data() {
    return {
      guardConfig: guardConfigDefaultFieldValues(),
      guardConfigCopy: null
    }
  },
  watch: {
    domains: function () {
      this.populateGuardConfig();
    }
  },
  methods: {
    populateGuardConfig() {
      if (!this.domains.length)
        return;

      const firstDomainGuardConfig = this.domains[0].guardConfig;

      for (let gcKey in firstDomainGuardConfig) {
        let equal = true;
        for (let domain of this.domains) {
          equal = isEqual(firstDomainGuardConfig[gcKey], domain.guardConfig[gcKey]);
          if (!equal) {
            break;
          }
        }

        this.guardConfig[gcKey] = equal ? firstDomainGuardConfig[gcKey] : MIXED_VALUE;
      }

      this.guardConfigCopy = JSON.stringify(this.guardConfig);
    },
    getGuardConfigUpdates() {
      this.$refs.guardConfiguration.processPendingValues();

      const guardConfig = {};

      for (let gcKey in this.guardConfig) {
        const isMixed = this.guardConfig[gcKey] === MIXED_VALUE;
        if (!isMixed) {
          guardConfig[gcKey] = this.guardConfig[gcKey];
        }
      }

      return guardConfig;
    },
    handleReset() {
      this.guardConfig = JSON.parse(this.guardConfigCopy);
    }
  }
}
</script>
