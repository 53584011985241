<template>
  <div>
  <CModal color="info" size="lg" :closeOnBackdrop="false" :show.sync="mutatedPatchShow" @hide="$emit('hide');" id="patch-modal">
    <CForm v-if="!stopConfirmModal" class="needs-validation" novalidate ref="domainForm">
      <CTabs>
        <CTab :title="$t('domain.BasicConfiguration')" active>
        <CCard style="margin-bottom: 5px">
          <CCardBody>
            <MultiDomainTrafficAction :domains="domains" ref="multiDomainTrafficAction"/>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="3">{{ $t('certificate_configuration') }}</CCol>
              <CCol sm="9">
                <input
                  type="checkbox"
                  class="custom-checkbox mr-2"
                  :checked="domain.sslConfig.isAutoGenerateCertificate"
                  @click="autoGenerateCertificate()"
                  id="auto_generate_certificate_checkbox"
                />
                <label class="mb-0 custom-label" for="auto_generate_certificate_checkbox">{{ $t('auto_generate_certificate') }}</label> <!-- Use the same id in the label's for attribute -->
              </CCol>
            </CRow>  
          </CCardBody>
        </CCard>
        <CCard>
            <CCardBody>
              <CRow>
                <CCol sm="3">{{ $t('notes') }}</CCol>
                <CCol sm="9">
                  <CTextarea :description="$t('optional')" v-model="notes"></CTextarea>
                </CCol>
              </CRow>
              
              <CRow v-if="domain.notes === mixedValueToken">
                <CCol sm="3">&nbsp;</CCol>
                <CCol sm="9">
                  <p  class="mixed-selection">
                    <CIcon name="cil-lightbulb"/>&nbsp;
                    <span>{{ $t('message.domain_settings_mixed_selection') }}</span>
                  </p>
                </CCol>
              </CRow>
              
            </CCardBody>
        </CCard>
        <div class="reset-btn-container">
          <CButton color="link" @click="handleResetBasicConfig">{{ $t('message.reset_basic_configuration') }}</CButton>
        </div>
      </CTab>
      <CTab :title="$t('domain.ShadowDomain')">
        <ShadowDomainDelete :selectedDomain="domains" @domains-updated="handleDomainsUpdated" />
      </CTab>
      <CTab :title="$t('domain.AdvancedConfiguration')">
        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="3">
                {{ $t('domain.CacheStrategy') }}
                <CBadge size="sm" color="info"
                  v-c-tooltip="{content: $t('message.cdn_intelligent_caching')}">i</CBadge>
              </CCol>
              <CCol sm="9">
                <CacheStrategyControl :domain="domain" ref="cacheStrategyControl"></CacheStrategyControl>
                <CAlert color="info" v-if="!domain.cacheConfig.cache_type">
                  {{$t('message.BatchCacheStrategyDifferent')}}
                </CAlert>
              </CCol>
            </CRow>
            </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>

        <CRow form class="form-group" id="edit-input-source">
          <CCol sm="3">
            {{ $t('domain.AdvancedRules') }}:
            <CBadge size="sm" color="info"
                  v-c-tooltip="{content: $t('message.intelligent_acceleration_defense')}">i</CBadge>
          </CCol>
          <CCol sm="9">
            <CListGroup>
              <CListGroupItem class="d-flex justify-content-between align-items-center">
                <div>
                  {{ $t('domain.HSTS') }}
                  <CBadge size="sm" color="info"
                    v-c-tooltip="{content: $t('helps.hsts_use')}">
                  ?
                  </CBadge>
                </div>
                <input type="checkbox" v-model="domain.sslConfig.is_hsts" />
              </CListGroupItem>
              <CListGroupItem class="d-flex justify-content-between align-items-center">
                <div>
                  {{ $t('domain.WildcardHSTS') }}
                  <CBadge size="sm" color="info"
                    v-c-tooltip="{content: $t('helps.wildcard_hsts_use')}">
                  ?
                  </CBadge>
                </div>
                <input type="checkbox" v-model="domain.sslConfig.is_wildcard_hsts" />
              </CListGroupItem>
              <CListGroupItem class="d-flex justify-content-between align-items-center">
                <div>
                  {{ $t('domain.SocketSupport') }}
                  <CBadge size="sm" color="info"
                    v-c-tooltip="{content: $t('helps.websockets_use')}">
                  ?
                  </CBadge>
                </div>

                <input type="checkbox"
                       value="websocket"
                       v-model="domain.strategies"
                       @change="handleWebsocketStrategyChange"
                       :indeterminate.prop="mixedContentStrategies" />
              </CListGroupItem>

              <CListGroupItem class="d-flex justify-content-between align-items-center">
                <div>
                  {{ $t('domain.DdosHighDefense') }}
                  <CBadge size="sm" color="info"
                    v-c-tooltip="{content: $t('helps.protection_reflection_attacks_etc')}">
                  ?
                  </CBadge>
                </div>
                <CBadge color="success"  @click="showContact()">{{ $t('VIP') }}</CBadge>
              </CListGroupItem>

            </CListGroup>
          </CCol>
        </CRow>
        </CCardBody>
        </CCard>

        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="3">
                {{ $t('domain.ACMEConfigure') }}
                <CBadge size="sm" color="info" v-c-tooltip="{content: $t('domain.ACMEConfigureDescription')}">i</CBadge>
                <div style="margin-top: 10px;">
                  <CButton :color="acmeEditMode ? 'light' : 'primary'" :outline="true" @click="toggleAcmeEditMode">
                    {{ acmeEditMode ? $t('domain.ACMEConfigurationCancelEdit') : $t('domain.ACMEConfigurationEdit') }}
                  </CButton>
                </div>
              </CCol>
              <CCol sm="9">
                <div v-if="acmeEditMode">
                  <CInputCheckbox :checked.sync="is_bypass_acme" :label="$t('domain.IsBypassACMEDescription')">
                  </CInputCheckbox>
                </div>
                <div v-else>
                  <p>{{ $t('domain.ACMEConfigureInstruction') }}</p>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <div class="reset-btn-container">
          <CButton color="link" @click="handleResetAdvancedConfiguration">{{ $t('message.reset_advanced_configuration') }}</CButton>
        </div>
      </CTab>
        <CTab :title="$t('domain.GuardConfiguration')">
          <MultiDomainGuardConfiguration :domains="domains" :isBatchEdit="true" ref="multiDomainGuardConfiguration"/>
        </CTab>
      </CTabs>
    </CForm>
    <div v-else>
      <CAlert color="danger">{{ $t('message.AfterDomainDeactivation') }}</CAlert>
      <CAlert color="info">
        <strong>{{ $t('contact_us.having_problems_message') }}</strong>
        <Contacts />
      </CAlert>
    </div>

    <template slot="header">
      <h5 class="modal-title">{{ $t('domain.BulkDomainConfiguration') }}</h5>
    </template>

    <template slot="footer-wrapper">
      <footer v-if="!stopConfirmModal" class="modal-footer" style="justify-content: space-between;">
        <CButtonGroup>
          <CButton
            color="danger"
            type="submit"
            size="sm"
            @click="confirmStop"
            v-c-tooltip="{content: $t('message.DeactivateDomainsAction'), appendToBody: true, delay: 500}"
            v-if="hasStatusActiveDomainSelections"
          >
            <CIcon name="cil-media-pause" />&nbsp;
            <span>{{ $t('Deactivate') }}</span>
          </CButton>
          <CButton
            color="danger"
            type="submit"
            size="sm"
            @click="resume"
            v-c-tooltip="{content: $t('message.ResumeDomainsAction'), appendToBody: true, delay: 500}"
            v-if="hasStatusDisabledDomainSelections"
          >
            <!--<CSpinner color="white" size="sm" v-if="processing" />-->
            <CIcon name="cil-media-play" />&nbsp;
            <span>{{ $t('Restore') }}</span>
          </CButton>
          <CButton
            color="danger"
            type="submit"
            size="sm"
            v-c-tooltip="{content: $t('message.RemoveDomainsAction'), appendToBody: true, delay: 500}"
            @click="remove"
          >
            <CSpinner color="white" size="sm" v-if="processing && isDeleteDomain" />
            <CIcon v-else name="cil-x-circle" />&nbsp;
            <span>{{ $t('Delete') }}</span>
          </CButton>
          </CButtonGroup>
        <CButtonGroup>
          <CButton @click="$emit('hide');" color="danger" :disabled="processing">{{ $t('Cancel') }}</CButton>
          <CButton color="primary" type="submit" @click="save" id="edit-submit" :disabled="processing || !isButtonEnabled">
            <CIcon name="cil-check" />&nbsp;
            <span>{{ $t('Save') }}</span>
          </CButton>
        </CButtonGroup>
      </footer>
      <footer v-else class="modal-footer" style="justify-content: space-between;">
        <CButtonGroup>
          <CButton @click="stopConfirmModal = false" color="success">{{ $t('contact_us.ContactCustomerServiceToHelpAccess') }}</CButton>
        </CButtonGroup>
        <div>
          <CButton @click="stopConfirmModal = false">{{ $t('Cancel') }}</CButton>
          <CButton
            type="submit"
            size="sm"
            @click="stop"
            color="danger"
          >
          <!--<CSpinner color="white" size="sm" v-if="processing" />-->
          <CIcon name="cil-media-pause" />&nbsp;
          <span>{{ $t('message.InsistOnDeactivating') }}</span>
          </CButton>
        </div>
      </footer>
    </template>
  </CModal>
  </div>
</template>

<style scoped>
.reset-btn-container {
  text-align: right;
}

.tab-pane > .card:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.mixed-selection {
  font-size: 0.9em;
  color: #825d17;
}
</style>

<script>
import {isEqual} from 'lodash';
import axios from "@/plugins/axios.js";
import Contacts from '@/containers/Contacts'
import { EventBus } from '@/plugins/event-bus.js';
import CacheStrategyControl from "@/views/domain/CacheStrategyControl";
import MultiDomainGuardConfiguration from "@/views/domain/MultiDomainGuardConfiguration";
import {DomainAction} from '@/utilities/websocket';
import {LIMIT_BATCH_DEPLOY} from '@/utilities/batch';
import MultiDomainTrafficAction from "@/views/domain/CountrySelector/MultiDomainTrafficAction";
import {MIXED_VALUE} from '@/utilities/constants';
import {deleteDomain} from '@/utilities/api';
import DomainValidationMixin from '@/utilities/DomainValidationMixin';
import ShadowDomainDelete from '@/views/domain/ShadowDomainDelete';

export default {
  name: "PatchDomain",
  mixins: [DomainValidationMixin],
  components: {
    MultiDomainTrafficAction,
    CacheStrategyControl,
    Contacts,
    MultiDomainGuardConfiguration,
    ShadowDomainDelete
  },
  computed: {
    groupsOptions: function () {
      return this.groups.map(g => { return {"value": g.id, "label": g.name} });
    },
    domain_ws() {
      return this.$store.state.websocket.domain_ws;
    },
    notes: {
      get() {
        if (this.domain.notes === MIXED_VALUE)
          return '';
        
        return this.domain.notes;
      },
      set(value) {
        this.domain.notes = value;
      }
    },
    is_bypass_acme: {
      get() {
        return this.domain.sourceConfig.is_bypass_acme || false; 
      },
      set(value) {
        this.domain.sourceConfig.is_bypass_acme = value;
      },
    },
    isButtonEnabled() {
      return this.invalidshadowdomain.length === 0 && this.duplicateshadowdomain.length === 0;
    }
  },
  data() {
    return {
      errors: [],
      selectedDomain:[],
      issueTask: null,
      stopConfirmModal: false,
      newAliasText: '',
      newRuleText: '',
      includeACMEBypassField: false,
      domain: {
        sourceConfig: {
          full_url: "",
          is_bypass_acme: true,
        },
        sslConfig: {
          is_hsts: false,
          is_wildcard_hsts: false,
          isAutoGenerateCertificate: false,
          is_show: false
        },
        cacheConfig: {
          cache_type: null,
          nocache: []
        },
        strategies: [],
        guardConfig: null,
        notes: ''
      },
      acmeEditMode: false,
      strategies_cleanall: false,
      mutatedPatchShow: false,
      mixedContentStrategies: false,
      processing: false,
      hasStatusActiveDomainSelections: false,
      hasStatusDisabledDomainSelections: false,
      queueProcesses: [],
      onProgress: [],
      isDeleteDomain: false,
      hasFailure: false,
      isSuccess: false,
      mutatedQueueDomainDeactivate: [],
      mutatedOnProgressDomainDeactivate: [],
      mutatedQueueDomainResume: [],
      mutatedOnProgressDomainResume: [],
      mutatedQueueDomainDelete: [],
      mutatedOnProgressDomainDelete: [],
      mixedValueToken: MIXED_VALUE,
      shadowdomaindelete:[],
      invalidshadowdomain:[],
      duplicateshadowdomain:[],
    };
  },
  watch: {
    patchShow() {
      this.mutatedPatchShow = this.patchShow;
      if (this.patchShow) {
        this.mutatedQueueDomainDeactivate = this.queueDomainDeactivate;
        this.mutatedOnProgressDomainDeactivate = this.onProgressDomainDeactivate;
        this.mutatedQueueDomainResume = this.queueDomainResume;
        this.mutatedOnProgressDomainResume = this.onProgressDomainResume;
        this.mutatedQueueDomainDelete = this.queueDomainDelete;
        this.mutatedOnProgressDomainDelete = this.onProgressDomainDelete;

        const uniqueSet = new Set(this.domains.map(d => d['sourceConfig']['full_url'] ))
        if(uniqueSet.size === 1) {
          this.domain['sourceConfig']['full_url'] = uniqueSet.values().next().value
        }

        this.computeCacheConfig();
        this.computeStrategies();
        this.computeDomainNotes();
        this.computeHSTS();
        this.computeWildCardHSTS();
        this.computeACMEBypass();

        this.hasStatusActiveDomainSelections = this.domains.some((d) => d.status !== 'disabled');
        this.hasStatusDisabledDomainSelections = this.domains.some((d) => d.status === 'disabled');

        this.$refs.multiDomainGuardConfiguration.populateGuardConfig();
      }
    },
    mutatedPatchShow() {
      this.$emit('update:patchShow', this.mutatedPatchShow)
    },
  },

  props: {
    domains: Array,
    patchShow: Boolean,
    is_root: Boolean,
    groups: Array,
    queueDomainDeactivate: Array,
    onProgressDomainDeactivate: Array,
    queueDomainResume: Array,
    onProgressDomainResume: Array,
    queueDomainDelete: Array,
    onProgressDomainDelete: Array
  },

  created() {
    this.mutatedPatchShow = this.patchShow
  },
  methods: {
    autoGenerateCertificate(){
      this.domain.sslConfig.isAutoGenerateCertificate = !this.domain.sslConfig.isAutoGenerateCertificate;
    },
    computeCacheConfig() {
      if (!this.domains.length)
        return;
      
      const firstCacheTypeComparison = this.domains[0].cacheConfig.cache_type;
      const hasSameCacheType = this.domains.every((d) => d.cacheConfig.cache_type === firstCacheTypeComparison);

      const uniqueNocacherules = new Set();

      const domain = {...this.domain};

      // check if domains have the same cache type and only override data if they are the same
      if (hasSameCacheType) {
        for (let d of this.domains) {
          for (let n of d['cacheConfig']['nocache']) {
            uniqueNocacherules.add(n);
          }
        }

        domain.cacheConfig.nocache = Array.from(uniqueNocacherules);
        domain.cacheConfig.cache_type = firstCacheTypeComparison;
      } else {
        domain.cacheConfig.nocache = [];
        domain.cacheConfig.cache_type = null;
      }

      this.domain = domain;
    },
    computeStrategies() {
      const firstStrategyComparison = this.domains[0].strategies;
      this.mixedContentStrategies = !this.domains.every((d) => isEqual(firstStrategyComparison, d.strategies));

      const uniqueStrategies = new Set(this.domains.map(d => JSON.stringify(d['strategies']) ))
      if (uniqueStrategies.size === 1) {
        this.domain['strategies'] = JSON.parse(uniqueStrategies.values().next().value)
      } else {
        this.domain['strategies'] = [];
      }
    },
    computeDomainNotes() {
      const sameDomainNotes = this.domains.every((d) => d.notes === this.domains[0].notes);
      this.domain.notes = sameDomainNotes ? this.domains[0].notes : MIXED_VALUE;
    },
    computeHSTS(){
      const hstsUniqueSet = new Set(this.domains.map(d => JSON.stringify(d['sslConfig']['is_hsts']) )).values().next().value
      if (hstsUniqueSet != undefined) {
        this.domain['sslConfig']['is_hsts'] = JSON.parse(hstsUniqueSet)
      } else {
        this.domain['sslConfig']['is_hsts'] = false;
      }
    },
    computeWildCardHSTS(){
      const wildcardhstsUniqueSet = new Set(this.domains.map(d => JSON.stringify(d['sslConfig']['is_wildcard_hsts']) )).values().next().value
      if (wildcardhstsUniqueSet != undefined) {
        this.domain['sslConfig']['is_wildcard_hsts'] = JSON.parse(wildcardhstsUniqueSet)
      } else {
        this.domain['sslConfig']['is_wildcard_hsts'] = false;
      }
    },
    showContact() {
      EventBus.$emit('show-contact');
    },
    computeACMEBypass() {
      const allBypassACMEValues = this.domains.map(d => d.sourceConfig.is_bypass_acme);
      const uniqueBypassACMEValues = new Set(allBypassACMEValues);

      if (uniqueBypassACMEValues.size === 1) {
        this.domain.sourceConfig.is_bypass_acme = uniqueBypassACMEValues.values().next().value;
      } else {
        this.domain.sourceConfig.is_bypass_acme = false; // or any default value
      }
    },
    addNewRule: function () {
      this.domain.cacheConfig.nocache.push(this.newRuleText)
      this.newRuleText = ''
      return true
    },
    toggleAcmeEditMode() {
      this.acmeEditMode = !this.acmeEditMode;
    },
    confirmStop() {
      this.stopConfirmModal = true;
    },
    sendDeploy(action, domains) {
      domains = domains.map(item => item.name);
      this.domain_ws.sendMessage(action, {domains: domains});
    },
    async remove() {
      this.processing = false;
      this.isSuccess = false;
      this.hasFailure = false;
      this.isDeleteDomain = true;
      this.$nextTick(() => {
        this.processing = true;
      })

      for (let d of this.domains) {
        const alreadyDeactivated = d.status === 'disabled';
        this.mutatedQueueDomainDelete.push(d);

        if (!alreadyDeactivated) {
          continue;
        }

        if (alreadyDeactivated) {
          EventBus.$emit('DeleteDomainRunningNotification', d);
          const deleteResponse = await deleteDomain(d.name, d);

          if (deleteResponse.status == 204 || deleteResponse.status == 404) {
            this.isSuccess = true;
          } else {
            this.hasFailure = true;
          }
        } else {
          this.hasFailure = true;
        }
        // Removing array from queueProcesses
        this.mutatedQueueDomainDelete = this.mutatedQueueDomainDelete.filter(item => item.name !== d.name);
      }

      if (this.mutatedOnProgressDomainDelete.length === 0) {
        this.mutatedOnProgressDomainDelete = this.mutatedQueueDomainDelete.splice(0, LIMIT_BATCH_DEPLOY);
        this.sendDeploy(DomainAction.DOMAIN_DELETE, this.mutatedOnProgressDomainDelete);
      }

      this.$emit('update:queueDomainDelete', this.mutatedQueueDomainDelete);
      this.$emit('update:onProgressDomainDelete', this.mutatedOnProgressDomainDelete);
      this.$emit('onDelete');
      this.$emit('hide');
    },
    async stop() {
      this.processing = false;
      this.isSuccess = false;
      this.hasFailure = false;
      this.isDeleteDomain = false;
      this.$nextTick(() => {
        this.processing = true;
      })

      this.mutatedQueueDomainDeactivate = this.mutatedQueueDomainDeactivate.concat(this.domains.filter(item => item.status !== 'disabled'));
      if (this.mutatedOnProgressDomainDeactivate.length === 0) {
        this.mutatedOnProgressDomainDeactivate = this.mutatedQueueDomainDeactivate.splice(0, LIMIT_BATCH_DEPLOY);
        this.sendDeploy(DomainAction.DOMAIN_DEACTIVATE, this.mutatedOnProgressDomainDeactivate);
      }

      this.stopConfirmModal = false;
      this.$emit('update:queueDomainDeactivate', this.mutatedQueueDomainDeactivate);
      this.$emit('update:onProgressDomainDeactivate', this.mutatedOnProgressDomainDeactivate);
      this.$emit('onDeactivate');
      this.$emit('hide');
    },
    async resume() {
      this.processing = false;
      this.isSuccess = false;
      this.hasFailure = false;
      this.isDeleteDomain = false;
      this.$nextTick(() => {
        this.processing = true;
      });
      this.mutatedQueueDomainResume = this.mutatedQueueDomainResume.concat(this.domains.filter(item => item.status === 'disabled'));
      if (this.mutatedOnProgressDomainResume.length === 0) {
        this.mutatedOnProgressDomainResume = this.mutatedQueueDomainResume.splice(0, LIMIT_BATCH_DEPLOY);
        this.sendDeploy(DomainAction.DOMAIN_RESUME, this.mutatedOnProgressDomainResume);
      }

      this.$emit('update:queueDomainResume', this.mutatedQueueDomainResume);
      this.$emit('update:onProgressDomainResume', this.mutatedOnProgressDomainResume);
      this.$emit('onResume');
      this.$emit('hide');
    },
    handleDomainsUpdated(domains) {
      const { domainsInput, invalidDomains, duplicateDomains } = domains;
      this.shadowdomaindelete = domainsInput
      this.duplicateshadowdomain = duplicateDomains
      this.invalidshadowdomain = invalidDomains
      if (this.duplicateshadowdomain == ['']){
        this.duplicateshadowdomain = []
      }
      else if (invalidDomains.length <= 0 && invalidDomains == ''){
        this.invalidshadowdomain = []
      }
    },
    async save() {
      let form = this.$refs.domainForm

      if (!form.checkValidity()) {
        this.flash(this.$t('message.DataVerificationError'), "error", { timeout: 3000 });
        return;
      }
      form.classList.add('was-validated');
    

      this.domain.sourceConfig = {...this.domain.sourceConfig, ...this.$refs.multiDomainTrafficAction.getDomainSourceConfig()}

      if (!this.acmeEditMode) delete this.domain.sourceConfig.is_bypass_acme;

      if (
          this.domain.sourceConfig.full_url !== MIXED_VALUE && !this.source_validator(this.domain.sourceConfig.full_url)
      ) {
        this.flash(this.$t('message.InputDataVerificationError'), "error", { timeout: 3000 });
        return;
      }

      if( this.newAliasText != "" && (this.addNewAlias() == false) ) {
        this.flash(this.$t('message.AliasInputError'), "error", { timeout: 3000 });
        return;
      }

      this.$refs.cacheStrategyControl.addNewRule();

      if ( this.strategies_cleanall ) {
        this.domain.strategies = []
      } else if (this.mixedContentStrategies) {
        // if the domain has mixed content strategies we null the strategies to signal
        // the save handler to not override the selected domain strategies
        this.domain.strategies = null;
      }

      this.domain.guardConfig = this.$refs.multiDomainGuardConfiguration.getGuardConfigUpdates();
      this.$emit('save', this.domain, this.shadowdomaindelete);
    },
    handleWebsocketStrategyChange() {
      this.mixedContentStrategies = false;
    },
    handleResetBasicConfig() {
      this.$refs.multiDomainTrafficAction.populateSourceConfig();
      this.computeDomainNotes();
    },
    handleResetAdvancedConfiguration() {
      this.computeCacheConfig();
      this.computeStrategies();
    }
  }
};
</script>
