<template>
    <div>
        <CModal color="info" size="lg" :closeOnBackdrop="false" :show.sync="mutatedShow" @hide="$emit('hide');" @save="$emit('save');" id="preset-modal" :addContentClasses="promoteShadowDomain === null ? '' : 'translucent-modal'">
          <template>
            <CCardBody>
              <CInput
                      type="text"
                      :placeholder="$t('Search Domain')"
                      v-model="search"
                      id="domain_search_input"
                      size="sm"
                    >
              </CInput>
              <CButton type="submit" id="domain_search_btn" color="primary" @click="filterBySearch()">{{ $t('Search') }}
              </CButton>
            </CCardBody>
          </template>
            <template>
              <CCardBody>
                <CListGroup>
                  <!-- List domains -->
                  <CListGroupItem v-for="domain in domainData" :key="domain.name" v-if="!isPluginDomain">
                    <div class="d-flex align-items-center">
                      <input
                        type="checkbox"
                        @click="filterByCheckbox(domain.name, domain)"
                        class="custom-checkbox mr-2"
                      />
                      <label class="mb-0 custom-label">{{ domain.name }}</label>
                    </div>
                  </CListGroupItem>


                  <!-- List plugins -->
                  <CListGroupItem v-for="plugin in domainPlugin" :key="plugin" v-if="isPluginDomain">
                    <input
                      type="checkbox"
                      @click="filterByPlugin(plugin)"
                      class="custom-checkbox mr-2"
                    />
                    <label class="mb-0 custom-label">{{ pluginData[plugin] }}</label>
                  </CListGroupItem>
                </CListGroup>
              </CCardBody>
            </template>
            <template slot="header" v-if="sourceDomain">
              <h5 class="modal-title">{{ $t('domain.PreSettingConfiguration') }}</h5>
            </template>

          <template slot="footer-wrapper">
            <footer class="modal-footer">
                <CButton color="danger" @click="cancel">{{ $t('Cancel') }}</CButton>
                <CButton color="primary" @click="save">{{ $t('OK') }}</CButton>
            </footer>
          </template>
          <template v-if="showError">
            <div class="alert alert-danger" role="alert">
              {{ $t('domain.NoDomainFound') }}
            </div>
          </template>            
      </CModal>
      <EditDomain :source-domain="newDomainData" @save="saveDomain" @hide="editModal=false" :editShow.sync="editModal" id="editModal" />
    </div>

  </template>
  
  <style>
    #edit-modal .modal-content {
      transition: opacity 0.2s ease-in-out;
    }
  
    .translucent-modal {
      opacity: 0;
    }
    
    .domain-input-description {
      font-size: 0.9em;
      color: #768192;
    }
    
    .domain-input-description span {
      text-decoration: underline;
    }
    .list-domain{
      list-style-type: none;

    }
    .domain-name {
      font-size: 1.2em;
    }
    .custom-checkbox {
      transform: scale(1.5); /* Adjust the scale factor to increase the size of the checkbox */
      margin-right: 8px; /* Adjust the margin as per your requirement */
    }
  </style>
  
  <style scoped>
    .shadow-list-action-btns {
      display: flex;
      align-items: center;
    }
  
    .promote-btn {
      margin-right: 10px;
      padding: 0;
    }
    
    .tab-pane > .card:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .error-message {
    color: red;
    }
    .subdomain-shadow-domain {
      background-color: #fbfbfb;
    }
    .custom-checkbox {
      width: 10px;
      height: 10px;
      vertical-align: middle;
    }
    .custom-label {
      font-size: 15px;
      padding-left: 20px;
    }
  </style>
  
  <script>
  import { EventBus } from '@/plugins/event-bus.js';
  import DomainValidationMixin from '@/utilities/DomainValidationMixin';
  import getDefaultDomainConfig from '@/utilities/getDefaultDomainConfig';
  import {promoteShadowDomain} from '@/utilities/api';
  import axios from "@/plugins/axios.js";
  import EditDomain from "./EditDomain";
  import { pluginData } from '@/utilities/constants';
  import {subscriptionPlan} from '../../utilities/api';

  const SORT_BY_OLDEST_TO_NEWEST = 'oldest_to_newest';
  const SORT_BY_NEWEST_TO_OLDEST = 'newest_to_oldest';
  const SORT_BY_NAME = 'name';
  
  
  export default {
    name: "PresetDomainConfiguration",
    mixins: [DomainValidationMixin],
    components: {
      EditDomain
    },
    computed:{
      domain_ws: {
        get() {
          return this.$store.state.websocket.domain_ws;
        },
        set(value) {
          this.$store.state.websocket.domain_ws = value;
        }
      },
    },
    watch: {
      presetShow() {
        this.mutatedShow = this.presetShow;
        this.fetchSubscriptions();
      },
     
      mutatedShow() {
        this.$emit('update:presetShow', this.mutatedShow)
      },
    },
    data() {
      return {
        search: "",
        domainData: [],
        domainPlugin:[],
        pluginData: pluginData,
        newDomainData : getDefaultDomainConfig(),
        selectedDomain:null,
        pluginArray: [],
        errors: [],
        editModal:false,
        issueTask: null,
        stopConfirmModal: false,
        newShadowName: '',
        newRuleText: '',
        perpage:25,
        page:1,
        cname:"",
        // domain: getDefaultDomainConfig(),
        activeTab: 0,
        guardConfigPendingValues: {},
        Domain: "",
        shadowsRefKey: 0,
        loadingRemove: false,
        pendingValues: {},
        formValidStates: {},
        mutatedShow: false,
        onDeploy: false,
        isDeleteDomain: false,
        promoteShadowDomain: null,
        promotingShadowDomain: false,
        shadowSearch: "",
        shadowSearchResults: null,
        isPluginDomain: false,
        sortOptions: [
          { value: SORT_BY_NAME, label: this.$t('name') },
          { value: SORT_BY_NEWEST_TO_OLDEST, label: this.$t('newest_to_oldest') },
          { value: SORT_BY_OLDEST_TO_NEWEST, label: this.$t('oldest_to_newest') },
        ],
        domainError : '',
        showError: false
      };
    },
    props: {
      sourceDomain: Object,
      presetShow: Boolean,
      is_root: Boolean,
    },
    mounted() {
      EventBus.$on('domainError', this.handleDomainError);
    },
    beforeDestroy() {
      EventBus.$off('domainError', this.handleDomainError);
    },
    methods: {
    filterBySearch() {
      this.domainPlugin = [];
      this.isPluginDomain = false;
      this.fetchDomain("");
    },
    filterByCheckbox(value,domain){
      this.selectedDomain = domain;
      this.domainPlugin = [];
      this.isPluginDomain = true;
      this.fetchDomain(value);
    },
    filterByPlugin(plugin){
      let pluginData = getDefaultDomainConfig();
      if(this.pluginArray.includes(plugin)){
        let index = this.pluginArray.indexOf(plugin);
        this.pluginArray.splice(index,1);
        if(this.selectedDomain[plugin]){
          this.newDomainData[plugin] = pluginData[plugin];
        }else{
          this.newDomainData.guardConfig[plugin] = pluginData.guardConfig[plugin];
        }
      }else{
        this.pluginArray.push(plugin);
        if(this.selectedDomain[plugin]){
          this.newDomainData[plugin] = this.selectedDomain[plugin];
        }else{
          this.newDomainData.guardConfig[plugin] = this.selectedDomain.guardConfig[plugin];
        }
      }
    },
    fetchSubscriptions(){
      return subscriptionPlan()
        .then(response => {
          let subscriptions = response.data;
          this.is_cname_loaded = true;
          if (subscriptions.length > 0 ) {
            this.plan = subscriptions[0].plan;
            this.cname = subscriptions[0].cname + "." + subscriptions[0].czone
            this.$store.state.cname = this.cname
          }
          else {
            axios
            .get('domain/user/config/', {
            })
            .then(response => {
              let userconfig = response.data;
              this.cname = userconfig.cname

              this.$store.state.cname = this.cname
            })
            .catch(error => {
              console.log(error);
            })
          }
          // .then(() => {
          //   if(this.items.length == 0 ) {
          //     this.guide()
          //   }
          // });
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchDomain(keyword) {
      this.search =  keyword ? keyword : this.search;
      //const params = '?page=' + ctx.currentPage + '&size=' + ctx.perPage
      this.listLoading = true;
      let filterParams = {
        page: this.page,
        perpage: this.perpage,
        _cname: this.cname
      }

      if((this.search)!="") {
        filterParams['search'] = this.search.trim()
      }else{
        filterParams['search'] = this.search
      }

      let searchDomains = [];
      if (this.findMultiDomain) {
        let findMultipleDomain = this.findMultiDomain.toLowerCase();
        findMultipleDomain = findMultipleDomain.split('\n');
        for (let i in findMultipleDomain) {
            const findDomainQ = findMultipleDomain[i].trim();
            if (findDomainQ == "") {
                continue;
            }
            if (findDomainQ.includes(' ')) {
                this.flash(this.$t('message.InvalidDomain',[findMultipleDomain[i]]),"error", { timeout: 3000 });
                return;
            }
            searchDomains.push(findDomainQ);
        }
        searchDomains = [...new Set(searchDomains)];
        filterParams['q1'] = searchDomains.join(",");
      }

      if (this.findBtnClicked) {
        this.findDomainModal = !this.findDomainModal;
        this.findBtnClicked = false;
      }

      if (this.certStatus) {
        filterParams['cert_status'] = this.certStatus;
      }
      if (this.domainStatus) {
        filterParams['status'] = this.domainStatus;
      }

      return axios
        .get('domain/', {
          params: filterParams,
          urlParams: {
          }
        })
        .then(response => {
          EventBus.$emit('updateDomainStat');
          this.items = response.data.results;
          this.domainData = response.data.results;
          if(this.isPluginDomain){
            let data = this.domainData[0];
            let guardPluginKey = Object.keys(data.guardConfig);
            guardPluginKey.push("proxy_host")
            guardPluginKey.push("strategies");
            guardPluginKey.push("sslConfig");
            guardPluginKey.push("cacheConfig");
            guardPluginKey.push("sourceConfig");
            this.domainPlugin = guardPluginKey;
          }
          this.items.forEach(item => {
            if (item.deploy_status == 'ready') {
              item.deploy_status = 'running'
              this.sendDeploy(DomainAction.DOMAIN_REDEPLOY, [item]);
            }
          })
          this.total_rows = response.data.count;
          this.total_pages = Math.ceil( this.total_rows / this.perpage );
          this.listLoading = false;
          this.showError = this.items.length === 0;
        })
        .catch(error => {
          this.listLoading = false;
          console.log(error);
        })
        .finally(() => {
          this.listLoading = false
        });
    },
    save(){
      this.presetShow = false;
      this.editModal = true;
      this.domainData = [];
      this.search = "";
      this.domainPlugin = [];
      this.showError = false;
    },
    cancel(){
      this.presetShow = false;
      this.domainData = [];
      this.search = "";
      this.domainPlugin = [];
      this.showError = false;
    },
    saveDomain(domain) {
      let isNew = domain.pk == null;
      if(! domain.cname) {
        domain._cname = this.cname;
      }
      axios({
        method: isNew ? "POST" : "PATCH",
        url: isNew
          ? `domain/`
          : `domain/{domain}/`,
        data: domain,
        urlParams: {
          domain: domain.name
        }
      }).then(response => {
          domain = response.data;
          if (response.status == "201") {
            this.flash(this.$t("domain.SaveSuccess"), "success", {
              timeout: 5000
            });

            let total_time = 30 + 60;
            let alpha = 5; 
            for (let _ in domain.shadows) {
              total_time += alpha + 60;
              alpha += 2;
            }

            total_time = Math.round(total_time / 60)

            let generateCertificate = true;
            if (domain.sslConfig && !domain.sslConfig.isAutoGenerateCertificate) {
              generateCertificate = false;
            }

            if (generateCertificate) {
              this.flash(this.$t("domain.AutoGenerateSSL", {total_time: total_time}), "success", {
                timeout: 5000
              });
            }
          }
        this.editModal = false
        //   this.$emit('hide');
        this.domain_ws.sendMessage(DomainAction.DOMAIN_REDEPLOY, {domains: [domain.name]})
        })
        .catch(errors => {
          errors.forEach((message) => {
            if (message.includes("0.")) {
              message = message.replace("0.", "");
              EventBus.$emit('domainError', message);
              this.flash(message,'error', { "timeout": 5000 });
            }else{
              const messages = message.replace("detail.", "");
              this.flash(messages,'error', { "timeout": 5000 });
            }
          });
        });
    },
    }
  };
  </script>
  
