<template>
  <div>
    <CIcon name="cil-pencil" />{{ $t('cluster.ServiceAreaUpgrade') }}
    <CRow v-if="domain && domain.deploy">
      <CCol lg="12">
        <CCard>
          <CCardHeader>{{ $t('cluster.CurrentServiceCluster') }}</CCardHeader>
          <CCardBody>
            <h5>{{ domain.deploy.cluster }}</h5>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol lg="12">
        <CCard>
          <CCardHeader>{{ $t('cluster.OptionalServiceCluster') }}</CCardHeader>

          <CCardBody>
            <CListGroup>
              <template v-for="cluster in clusters">
                <CListGroupItem
                  href="#"
                  :active="cluster == selectedCluster"
                  @click="selectedCluster=cluster"
                  class="flex-column align-items-start"
                  :key="cluster.name"
                >
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{ cluster.vars.label_cn }}</h5>
                    <small>{{ cluster.name }}</small>
                    <CBadge v-if="'vip' in cluster.catalogs">VIP</CBadge>
                  </div>
                </CListGroupItem>
              </template>
            </CListGroup>
          </CCardBody>
          <CCardFooter>
            <CButton color="primary" type="submit" @click="upgradeCluster">
              <CIcon name="cil-check" />&nbsp;{{ $t('Upgrade') }}
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "@/plugins/axios.js";

export default {
  name: "ClusterUpgrade",
  computed: {
    deploy: function() {
      return this.domain ? this.domain.deploy : {};
    }
  },
  data() {
    return {
      listLoading: false,
      clusters: [],
      selectedCluster: null
    };
  },
  props: {
    domain: Object
  },
  mounted() {
    // this.selectedDomain = newDomain;
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.listLoading = true;

      axios
        .get(`cmdb/cluster/`, {
          params: {
            "catalog": "cdnnode"
          },
          urlParams: {
          }
        })
        .then(response => {
          this.clusters = response.data.results;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => (this.listLoading = false));
    },

    upgradeCluster() {
      axios({
        method: "POST",
        url: `domain/{domain}/cluster/`,
        data: {
          cluster: this.selectedCluster
        },
        urlParams: {
          domain: this.domain.domain
        }
      })
        .then(response => {
          this.domain.deploy = response.data.deploy;
          this.flash(this.$t("cluster.upgradeSuccess"), "success", { timeout: 3000 });
          this.$emit("hide");
        })
        .catch(function(errors) {
          errors.forEach((message) => {
            this.flash(message, 'error', { "timeout": 5000 });
          });
        });
    }
  }
};
</script>
